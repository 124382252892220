import { all } from "redux-saga/effects";

import sessionSaga from "./session/saga";
import playerSaga from "./player/saga";
import screenSaga from "./screen/saga";
import masterSaga from "./master/saga";

function* rootSaga() {
  yield all([sessionSaga(), playerSaga(), screenSaga(), masterSaga()]);
}

export default rootSaga;
