import React from "react";
import PropTypes from "prop-types";

class Countdown extends React.Component {
  static propTypes = {
    to: PropTypes.instanceOf(Date).isRequired,
    when: PropTypes.func,
    children: PropTypes.func,
  };

  static defaultProps = {
    when: (remaining) => true,
    children: (remaining) => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      remaining: undefined,
    };
    this.lastSeenRemaining = null;
  }

  componentWillMount() {
    this.handleCalculation();
  }

  componentDidMount() {
    this.startInterval();
  }

  componentWillReceiveProps({ to: nextTo }) {
    const { to } = this.props;
    if (nextTo !== to) {
      this.startInterval();
    }
  }

  componentWillUnmount() {
    this.stopInterval();
  }

  startInterval() {
    this.stopInterval();
    this.calculationInterval = window.setInterval(this.handleCalculation, 150);
  }

  stopInterval() {
    window.clearInterval(this.calculationInterval);
  }

  handleCalculation = () => {
    const { to, when: keepGoing } = this.props;

    const toAsTimestamp = Math.floor(to.getTime() / 1000);
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const remaining = toAsTimestamp - currentTimestamp;

    if (remaining !== this.lastSeenRemaining) {
      this.lastSeenRemaining = remaining;
      this.setState({ remaining });

      if (!keepGoing(remaining)) {
        this.stopInterval();
      }
    }
  };

  render() {
    const { children } = this.props;
    const { remaining } = this.state;

    return children(remaining);
  }
}

export default Countdown;
