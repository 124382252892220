import { call, put } from "redux-saga/effects";
import { startSubmit, setSubmitSucceeded, stopSubmit } from "redux-form";

import masterLoginSaga from "./masterLoginSaga";

export default function* ({ payload: { form, password } }) {
  yield put(startSubmit(form));
  const { ok, error } = yield call(masterLoginSaga, password);
  if (error) {
    const {
      payload: { reason },
    } = error;
    yield put(stopSubmit(form, { _error: reason }));
  }
  if (ok) {
    yield put(setSubmitSucceeded(form));
    yield put(stopSubmit(form));
  }
}
