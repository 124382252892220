import { takeLeading, takeLatest, all } from "redux-saga/effects";

import matchChannelEvent from "../../socket/util/matchChannelEvent";
import * as action from "../action";
import getMasterChannelName from "./getMasterChannelName";
import masterLoginFormSubmittedSaga from "./masterLoginFormSubmittedSaga";
import restoreMasterLoginSaga from "./restoreMasterLoginSaga";
import masterLogoutSaga from "./masterLogoutSaga";
import gameInfoReceivedSaga from "./gameInfoReceivedSaga";
import madeChoicesUpdatedReceivedSaga from "./madeChoicesUpdatedReceivedSaga";
import readyListUpdatedReceivedSaga from "./readyListUpdatedReceivedSaga";
import displayEventReceivedSaga from "./displayEventReceivedSaga";
import gameStartRequestedSaga from "./gameStartRequestedSaga";
import gameResetRequestedSaga from "./gameResetRequestedSaga";
import gameNextRequestedSaga from "./gameNextRequestedSaga";
import gamePreviousRequestedSaga from "./gamePreviousRequestedSaga";
import * as livestream from "./livestreamSagas";
import * as countdown from "./countdownSagas";

const masterChannelEventMatch = (event) => (action) =>
  matchChannelEvent(getMasterChannelName(), event)(action);

export default function* () {
  yield all([
    restoreMasterLoginSaga(),
    takeLeading(action.masterLoginFormSubmitted, masterLoginFormSubmittedSaga),
    takeLeading(action.masterLogoutRequested, masterLogoutSaga),

    takeLeading(action.gameStartRequested, gameStartRequestedSaga),
    takeLeading(action.gameResetRequested, gameResetRequestedSaga),
    takeLeading(action.gameNextRequested, gameNextRequestedSaga),
    takeLeading(action.gamePreviousRequested, gamePreviousRequestedSaga),

    takeLeading(
      action.livestreamCreateRequested,
      livestream.livestreamCreateRequestedSaga
    ),
    takeLeading(
      action.livestreamStartRequested,
      livestream.livestreamStartRequestedSaga
    ),
    takeLeading(
      action.livestreamStopRequested,
      livestream.livestreamStopRequestedSaga
    ),
    takeLeading(
      action.livestreamDeleteRequested,
      livestream.livestreamDeleteRequestedSaga
    ),

    takeLeading(action.countdownStartRequested, countdown.countdownStartSaga),
    takeLeading(action.countdownStopRequested, countdown.countdownStopSaga),

    takeLatest(masterChannelEventMatch("game_info"), gameInfoReceivedSaga),
    takeLatest(
      masterChannelEventMatch("made_choices_updated"),
      madeChoicesUpdatedReceivedSaga
    ),
    takeLatest(
      masterChannelEventMatch("ready_list_updated"),
      readyListUpdatedReceivedSaga
    ),
    takeLatest(
      masterChannelEventMatch("display_event"),
      displayEventReceivedSaga
    ),
  ]);
}
