import React from "react";
import * as toast from "react-toastify";

import HTMLContent from "./component/HTMLContent";

const showRawHTMLToast = (message) => showToast(<HTMLContent html={message} />);

const showToast = (message, opts = {}) =>
  toast.toast.info(message, {
    position: "bottom-center",
    autoClose: 30000,
    pauseOnVisibilityChange: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    draggable: true,
    closeOnClick: true,
    transition: toast.Flip,
    className: "hint-toast",
    bodyClassName: "hint-toast__body",
    ...opts,
  });

const hideToast = (id) => toast.toast.dismiss(id);

export { showToast, showRawHTMLToast, hideToast };
