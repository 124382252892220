import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import configureStore from "./state/configureStore";
import Root from "./Root";

import { cssNode } from "./util/dom";

import "./sass/index.scss";

const store = configureStore();
const rootElement = document.getElementById("root");

const render = () => {
  ReactDOM.render(<Root store={store} />, rootElement);
};

if (process.env.NODE_ENV === "development") {
  const { custom_main_css } = window.env;
  if (custom_main_css) {
    const defaultMainCSS = document.querySelector("head > style");
    const newMainCSS = cssNode(custom_main_css);
    if (defaultMainCSS !== null) {
      defaultMainCSS.parentElement!.replaceChild(newMainCSS, defaultMainCSS);
    }
  }
}

render();
if (module.hot) {
  module.hot.accept("./Root", render);
}
