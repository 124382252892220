import { put } from "redux-saga/effects";

import * as action from "../action";

export default function* ({ payload }) {
  const {
    game_info,
    made_choices,
    ready_list,
    previous_possible,
    next_finishes_game,
  } = payload;
  yield put(action.gameInfoReceived(game_info));
  yield put(action.madeChoicesReceived(made_choices));
  yield put(action.readyListReceived(ready_list));
  yield put(action.previousPossibleReceived(previous_possible));
  yield put(action.nextFinishesGameReceived(next_finishes_game));
}
