import { call, put } from "redux-saga/effects";

import channelPushSaga from "../../socket/util/channelPushSaga";
import * as action from "../action";
import getMasterChannelName from "./getMasterChannelName";

export default function* () {
  yield put(action.gameResetStarted());

  const channel = yield call(getMasterChannelName);
  const { ok, error } = yield call(channelPushSaga, channel, "reset_game");

  if (ok) {
    yield put(action.gameResetSucceeded());
  } else {
    yield put(action.gameResetFailed());
  }

  if (ok || error) {
    const {
      game_info,
      made_choices,
      ready_list,
      previous_possible,
      next_finishes_game,
    } = ok || error;
    yield put(action.gameInfoReceived(game_info));
    yield put(action.madeChoicesReceived(made_choices));
    yield put(action.readyListReceived(ready_list));
    yield put(action.previousPossibleReceived(previous_possible));
    yield put(action.nextFinishesGameReceived(next_finishes_game));
  }
}
