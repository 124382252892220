import { put, take, call, race } from "redux-saga/effects";
import { startSubmit, setSubmitSucceeded, stopSubmit, reset } from "redux-form";
import axios from "axios";
import get from "lodash/get";

import { AUTH_URL } from "../../../settings";

import * as socketAction from "../../socket/action";
import { setAuth } from "../../authStorage";

import * as action from "../action";
import { logoutSaga } from "./logoutSaga";
import joinLobbySaga from "./joinLobbySaga";
import calculateServerTimeDifference from "./calculateServerTimeDifference";

const translateErrorMessage = (error) => {
  switch (error) {
    case "credentials_invalid":
      return "Pincode onjuist";
    case "too_many_requests":
      return "Rustig aan graag";
    default:
      return "Er is iets fout gegaan bij het inloggen. Probeer het later opnieuw";
  }
};

export default function* loginFormSubmittedSaga({ payload: { form, pin } }) {
  yield put(startSubmit(form));

  // login with username and password to get a token
  // On error, report back to the user and abort
  let auth;
  try {
    const response = yield call(axios.post, AUTH_URL, { pin });
    auth = response.data;
  } catch (error) {
    let errorResponse;
    if (error.response.status === 400) {
      errorResponse = error.response.data.error;
    } else if (error.response.status === 429) {
      errorResponse = "too_many_requests";
    } else {
      errorResponse = error.toString();
    }
    errorResponse = translateErrorMessage(errorResponse);
    yield put(stopSubmit(form, { _error: errorResponse }));
    yield put(action.loginFailed(errorResponse));
    return;
  }

  const { token, session_id: sessionId } = auth;

  // Send a socket connect request to our socket middleware
  // with the token we received from the login
  // On error, report back to the user and abort
  yield put(socketAction.connectRequested({ params: { token } }));
  const { connectFailed } = yield race({
    connectSucceeded: take(socketAction.connectSucceeded),
    connectFailed: take(socketAction.connectFailed),
  });
  if (connectFailed) {
    const errorResponse = "Socket connection failed";
    yield put(stopSubmit(form, { _error: errorResponse }));
    yield put(action.loginFailed(errorResponse));
    return;
  }

  // Join the lobby channel and setup event listening
  // On error, report back to the user and abort
  const joinStartTime = new Date();
  const { ok: joinSucceeded, error: joinFailed } = yield call(
    joinLobbySaga,
    sessionId
  );
  if (joinFailed) {
    const errorResponse = joinFailed.payload.reason;
    yield put(stopSubmit(form, { _error: errorResponse }));
    yield put(action.loginFailed(errorResponse));
    return;
  }

  // Determine server time difference
  const joinEndTime = new Date();
  const serverTime = new Date(joinSucceeded.payload.server_time);
  const serverTimeDifference = yield call(
    calculateServerTimeDifference,
    joinStartTime,
    joinEndTime,
    serverTime
  );
  yield put(action.serverTimeDifferenceCalculated(serverTimeDifference));

  // Apply settings (if any)
  const {
    payload: { custom_css: customCSS, locale },
  } = joinSucceeded;
  if (customCSS) {
    yield put(action.customCSSAdded(customCSS));
  }
  if (locale) {
    yield put(action.localeSelected(locale));
  }
  yield put(
    action.setScreenAsPlayer(
      get(joinSucceeded, "payload.client.screen_as_player")
    )
  );

  // Report login success
  yield put(stopSubmit(form));
  yield put(setSubmitSucceeded(form));
  yield put(reset(form));
  yield put(action.loginSucceeded());

  // Save the token for later
  yield call(setAuth, auth);

  // Wait for logout
  yield call(logoutSaga);
}
