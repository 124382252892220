import React from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import { Localized } from "../../translation";

import { playernameFormSubmitted } from "../../state/player";

import { FormInputField, FormError, required, handleSubmit } from "../form";

const PlayernameForm = reduxForm({
  form: "playernameForm",
  onSubmit: handleSubmit(({ playername }, dispatch, { form }) => {
    if (playername) {
      dispatch(playernameFormSubmitted(form, playername));
    }
  }),
})(({ handleSubmit, pristine, submitting, error }) => (
  <form onSubmit={handleSubmit} autoComplete="off" className="login-form">
    <div className="login-form__fields">
      {error && <FormError error={error} />}
      <Field
        name="playername"
        component={FormInputField}
        type="text"
        label={<Localized id="generic-playername" />}
        validate={[required]}
      />
    </div>
    <div className="login-form__submit">
      <button type="submit" disabled={pristine || submitting}>
        <Localized id="form-submit">Submit</Localized>
      </button>
    </div>
  </form>
));

const PlayernameChooserPage = () => (
  <div className="login-page">
    <div className="login-page__header">
      <Localized id="login-page-header">
        <h1>Welcome</h1>
      </Localized>
    </div>
    <div className="login-page__form">
      <PlayernameForm />
    </div>
  </div>
);

export default PlayernameChooserPage;
