import React from "react";

import QuestionText from "./QuestionText";
import QuestionTextAndChoices from "./QuestionTextAndChoices";
import QuestionResults from "./QuestionResults";

const Question = ({
  nickname,
  displayEvent,
  madeChoice,
  serverTimeDifference,
  onChoice,
}) => {
  const {
    payload: {
      text,
      choices,
      choices_end_time: choicesEndTime,
      statistics,
      screen_text: screenText,
    },
  } = displayEvent;

  /**
   * In case the choices are not available yet just
   * render the text
   */
  if (!choices) {
    return <QuestionText text={text} />;
  }

  /**
   * When there are no statistics, display the choice buttons
   */
  if (!statistics) {
    return (
      <QuestionTextAndChoices
        {...{
          text,
          choices,
          madeChoice,
          screenText,
          choicesEndTime,
          serverTimeDifference,
          onChoice,
        }}
      />
    );
  }

  /**
   * Otherwise we have statistics and therefore should
   * display the made choices/results
   */
  return (
    <QuestionResults
      {...{
        text,
        choices,
        madeChoice,
        screenText,
        statistics,
        nickname,
      }}
    />
  );
};

export default Question;
