import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button } from "react-bootstrap";
import { AiOutlineMenu } from "react-icons/ai";

import {
  nicknameSetRequested,
  openRepositoryRequested,
  openInventoryRequested,
} from "../../state/player";
import { Localized } from "../../translation";

const HeaderMenuToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button variant="light" ref={ref} onClick={onClick}>
    <AiOutlineMenu />
  </Button>
));

const HeaderMenu = () => {
  const dispatch = useDispatch();

  const repository = useSelector(({ player }) => player.repository);
  const handleOpenRepositoryClick = () => dispatch(openRepositoryRequested());

  const inventory = useSelector(({ player }) => player.inventory);
  const handleOpenInventoryClick = () => dispatch(openInventoryRequested());

  const handleLeaveTeamClick = () => dispatch(nicknameSetRequested(undefined));

  return (
    <Dropdown>
      <Dropdown.Toggle as={HeaderMenuToggle} />
      <Dropdown.Menu className="header-menu">
        {repository.length > 0 && (
          <Dropdown.Item
            onClick={handleOpenRepositoryClick}
            className="header-menu__item header-menu__item--for--repository"
          >
            <Localized id="repository">repository</Localized>
          </Dropdown.Item>
        )}
        {inventory.length > 0 && (
          <Dropdown.Item
            onClick={handleOpenInventoryClick}
            className="header-menu__item header-menu__item--for--inventory"
          >
            <Localized id="inventory">inventory</Localized>
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onClick={handleLeaveTeamClick}
          className="header-menu__item header-menu__item--for--switch-team"
        >
          <Localized id="switch-team">switch-team</Localized>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HeaderMenu;
