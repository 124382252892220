import React from "react";
import cx from "classnames";

import HTMLContent from "../HTMLContent";

import ScreenCountdown from "./ScreenCountdown";

const ScreenQuestion = ({
  displayEvent: {
    payload: {
      text,
      choices,
      choices_end_time: choicesEndTime,
      statistics,
      screen_text: screenText,
    },
  },
  serverTimeDifference,
}) => {
  if (!choices) {
    return (
      <div className={cx("screen-question", "screen-question--display--text")}>
        <div className="screen-question__content">
          <HTMLContent html={text} />
        </div>
      </div>
    );
  }

  if (!statistics) {
    return (
      <div
        className={cx(
          "screen-question",
          "screen-question--display--text-and-choices",
          `screen-question--choices-count--${choices.length}`
        )}
      >
        <div className="screen-question__content">
          <HTMLContent html={text} />
        </div>
        {screenText ? (
          <div className="screen-question__content">
            <HTMLContent html={screenText} />
          </div>
        ) : (
          <div className="screen-question__choices">
            {choices.map((choice, index) => (
              <div
                className={cx(
                  "screen-question__choice",
                  `screen-question__choice--index--${index}`
                )}
                key={index}
              >
                <div className="screen-question__choice__content">
                  <HTMLContent html={choice.content} />
                </div>
              </div>
            ))}
          </div>
        )}
        {choicesEndTime &&
          (() => {
            const correctedTime =
              new Date(choicesEndTime).getTime() + serverTimeDifference;
            const correctedTimeObj = new Date();
            correctedTimeObj.setTime(correctedTime);
            return (
              <ScreenCountdown
                to={correctedTimeObj}
                className="screen-question__countdown"
              />
            );
          })()}
      </div>
    );
  }

  return (
    <div
      className={cx(
        "screen-question",
        "screen-question--display--statistics",
        `screen-question--choices-count--${choices.length}`
      )}
    >
      <div className="screen-question__content">
        <HTMLContent html={text} />
      </div>
      {screenText ? (
        <div className="screen-question__content">
          <HTMLContent html={screenText} />
        </div>
      ) : (
        <div className="screen-question__choices">
          {choices.map((choice, index) => {
            const choiceHasPoints = choice.points !== undefined;
            const pointsReceived = choice.points;
            const showCorrect = choiceHasPoints && pointsReceived;
            const showIncorrect = choiceHasPoints && !pointsReceived;
            const chosenCount = statistics.choices[index];
            return (
              <div
                className={cx(
                  "screen-question__choice",
                  `screen-question__choice--index--${index}`,
                  { "screen-question__choice--correct": showCorrect },
                  { "screen-question__choice--not-correct": showIncorrect }
                )}
                key={index}
              >
                <HTMLContent html={choice.content} />

                {(showCorrect || showIncorrect) && (
                  <div className="screen-question__choice__points">
                    <div className="screen-question__choice__points__stats">
                      {chosenCount}
                    </div>
                    <div className="screen-question__choice__points__received">
                      {pointsReceived}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ScreenQuestion;
