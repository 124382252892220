import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import md5 from "crypto-js/md5";

import { Dropdown, Button } from "react-bootstrap";

import { Localized } from "../../translation";
import { getTeamPresence } from "../../state/screen";
import { getPlayername } from "../../state/player";

const TeamInfo = ({ nickname, label }) => {
  const playername = useSelector(({ player }) => getPlayername(player));
  const teamPresence = useSelector(
    ({ screen }) => getTeamPresence(screen)[nickname] || []
  );

  if (!teamPresence.length) {
    return null;
  }

  const CustomToggle = React.forwardRef(({ onClick, ...props }, ref) => (
    <Button
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      {...props}
    />
  ));

  return (
    <Dropdown className="team-info-container">
      <Dropdown.Toggle
        id={`team-info--${md5(nickname)}`}
        variant="light"
        as={CustomToggle}
      >
        {label || nickname}
      </Dropdown.Toggle>
      <Dropdown.Menu className="team-info">
        {teamPresence.map((teamPlayername, index) => (
          <Dropdown.Item
            key={index}
            className={cx(
              "team-info__item",
              { "team-info__item--leader": index === 0 },
              { "team-info__item--not-leader": index !== 0 },
              { "header-team-info__item--self": playername === teamPlayername },
              {
                "header-team-info__item--not-self":
                  playername !== teamPlayername,
              }
            )}
          >
            <Localized
              id="screen-as-player-page-header-team"
              $role={index === 0 ? "leader" : "member"}
              $name={teamPlayername}
            >
              {"{$role}: {$name}"}
            </Localized>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TeamInfo;
