import update from "immutability-helper";

/**
 * Given an action creator (from redux-act), create a function which takes a
 * correlationId and returns yet another function which matches actions from the
 * initially given action creator AND the provided correlationId.
 * This may be useful in combination with redux-saga when correlating actions
 * is needed. You then can take the action from the action creator, put
 * a correlationId in it's meta and within your custom middleware dispatch actions
 * including the correlationId which is related to the to be dispatched action.
 */
export const createActionCorrelationIdMatcher = (actionCreator) => (
  correlationId
) => (action) => {
  const { type, meta: { correlationId: incomingCorrelationId } = {} } = action;
  const actionCreatorString = actionCreator.toString();
  const actionCreatorMatches = type === actionCreatorString;
  const correlationIdMatches = correlationId === incomingCorrelationId;
  return actionCreatorMatches && correlationIdMatches;
};

/**
 * Complementary to `createActionCorrelationIdMatcher`, set the correlationId
 * in a given action.
 *
 * This may be of use in any place where action creators create actions
 * that should be correlated (e.g. within sagas and middlewares).
 */
export const setActionCorrelationId = (action, correlationId) =>
  update(action, {
    meta: (meta) =>
      update(meta || {}, { correlationId: { $set: correlationId } }),
  });
