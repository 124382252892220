import { createAction } from "redux-act";

export const nicknameSetRequested = createAction(
  "nickname set requested",
  (nickname) => nickname
);

export const nicknameSet = createAction("nickname set", (nickname) => nickname);

export const nicknameUnsetRequested = createAction("nickname unset requested");
export const nicknameUnset = createAction("nickname unset");

export const nicknameFormSubmitted = createAction(
  "nickname form submitted",
  (form, nickname) => ({ form, nickname })
);

export const playernameSetRequested = createAction(
  "playername set requested",
  (playername) => playername
);

export const playernameSet = createAction(
  "playername set",
  (playername) => playername
);

export const playernameUnsetRequested = createAction(
  "playername unset requested"
);
export const playernameUnset = createAction("playername unset");

export const playernameFormSubmitted = createAction(
  "playername form submitted",
  (form, playername) => ({ form, playername })
);

export const isTeamLeaderUpdated = createAction(
  "teamleader updated",
  (isTeamLeader) => isTeamLeader
);

export const displayEventReceived = createAction(
  "display event received",
  (event) => event
);

export const scoreUpdated = createAction("score updated", (score) => score);

export const gameStarted = createAction("game started");
export const gameReset = createAction("game was reset");

export const makeChoiceRequested = createAction(
  "make choice requested",
  (choiceIndex) => choiceIndex
);
export const choiceUpdated = createAction(
  "choice updated",
  (choiceIndex) => choiceIndex
);
export const clearMadeChoice = createAction("clear made choice");
export const isReadyChangeRequested = createAction(
  "is_ready change requested",
  (value) => value
);
export const isReadyUpdated = createAction(
  "is_ready updated",
  (value) => value
);

export const openRepositoryRequested = createAction(
  "open repository requested"
);
export const closeRepositoryRequested = createAction(
  "close repository requested"
);

export const openInventoryRequested = createAction("open inventory requested");
export const closeInventoryRequested = createAction(
  "close inventory requested"
);
