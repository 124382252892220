import * as socketAction from "../action";

const matchChannelAction = (channel, event) => (action) => {
  const {
    type,
    meta: { channel: actionChannel, event: actionEvent } = {},
  } = action;

  return (
    type === socketAction.channelEventReceived.toString() &&
    channel === actionChannel &&
    event === actionEvent
  );
};

export default matchChannelAction;
