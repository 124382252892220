import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";

import { getNickname, getScore, getRank } from "../../state/player/selector";

const PlayerMeta = () => {
  const nickname = useSelector(({ player }) => getNickname(player));
  const score = useSelector(({ player }) => getScore(player));
  const rank = useSelector(({ player }) => getRank(player));
  return (
    <div
      className={cx(
        "player-meta",
        { "player-meta--score": !!score },
        { "player-meta--no-score": !score }
      )}
    >
      <div className="player-meta__nickname">{nickname}</div>
      {(score || rank) && (
        <div className="player-meta__score">
          <div className="player-meta__score__points">{score}</div>
          <div className="player-meta__score__rank">{rank}</div>
        </div>
      )}
    </div>
  );
};

export default PlayerMeta;
