import { Socket } from "phoenix";

import { createMiddlewareHandlers } from "../util/middleware";

import * as action from "./action";

import { setActionCorrelationId } from "./util/actionCorrelationId";

const noop = () => {};

const createSocketMiddleware = (url) => {
  let socket;
  let channels = {};

  return createMiddlewareHandlers({
    [action.connectRequested]: ({
      store: { dispatch },
      next,
      action: { payload },
      action: incomingAction,
    }) => {
      next(incomingAction);

      if (socket) {
        socket.disconnect();
      }

      const {
        params,
        onOpen = noop,
        onClose = noop,
        onDisconnect = noop,
        socketOptions,
      } = payload;

      const mergedSocketOptions = {
        //logger: (kind, msg, data) => console.log({kind, msg, data}),
        reconnectAfterMs: (tries) => [1000, 2000, 3000][tries - 1] || 3000,
        ...socketOptions,
        params,
      };

      socket = new Socket(url, mergedSocketOptions);

      socket._initialConnect = true;

      socket.onOpen(() => {
        onOpen(socket);
        socket._initialConnect = false;
        dispatch(action.connectSucceeded());
      });

      socket.onError(() => {
        if (socket._initialConnect) {
          socket.disconnect();
          dispatch(action.connectFailed());
        }
      });

      socket.onClose(() => {
        onClose(socket);
        dispatch(action.connectionDropped());
      });

      socket._onDisconnect = onDisconnect;

      socket.connect();

      channels = {};
    },

    [action.disconnectRequested]: ({
      store: { dispatch },
      next,
      action: incomingAction,
    }) => {
      next(incomingAction);

      if (socket) {
        socket.disconnect(() => {
          socket._onDisconnect(socket);
          dispatch(action.disconnectSucceeded());
        });

        channels = {};
      }
    },

    [action.channelJoinRequested]: ({
      store: { dispatch },
      next,
      action: {
        payload: { channel, params, events },
      },
      action: incomingAction,
    }) => {
      next(incomingAction);

      if (channels[channel]) {
        dispatch(
          action.channelJoinFailed(channel, { reason: "already_joined" })
        );
      } else {
        channels[channel] = socket.channel(channel, params);
        dispatch(action.joinedChannelsSet(Object.keys(channels)));

        events.forEach((event) => {
          channels[channel].on(event, (data) =>
            dispatch(action.channelEventReceived(channel, event, data))
          );
        });

        channels[channel]
          .join()
          .receive("ok", (data) =>
            dispatch(action.channelJoinSucceeded(channel, data))
          )
          .receive("error", (error) => {
            dispatch(action.channelJoinFailed(channel, error));
            channels[channel].leave();
            delete channels[channel];
            dispatch(action.joinedChannelsSet(Object.keys(channels)));
          })
          .receive("timeout", () => {
            dispatch(action.channelJoinTimeout(channel));
            delete channels[channel];
            dispatch(action.joinedChannelsSet(Object.keys(channels)));
          });
      }
    },

    [action.channelListenRequested]: ({
      store: { dispatch },
      next,
      action: {
        payload: event,
        meta: { channel },
      },
      action: incomingAction,
    }) => {
      next(incomingAction);

      if (channels[channel]) {
        channels[channel].on(event, (data) =>
          dispatch(action.channelEventReceived(channel, event, data))
        );
      }
    },

    [action.channelPushRequested]: ({
      store: { dispatch },
      next,
      action: {
        payload,
        meta: { channel, event, correlationId },
      },
      action: incomingAction,
    }) => {
      next(incomingAction);

      if (channels[channel]) {
        channels[channel]
          .push(event, payload)
          .receive("ok", (data) =>
            dispatch(
              setActionCorrelationId(
                action.channelPushReceivedOk(channel, event, data),
                correlationId
              )
            )
          )
          .receive("error", (data) =>
            dispatch(
              setActionCorrelationId(
                action.channelPushReceivedError(channel, event, data),
                correlationId
              )
            )
          )
          .receive("timeout", () =>
            dispatch(
              setActionCorrelationId(
                action.channelPushReceivedTimeout(channel, event),
                correlationId
              )
            )
          );
      }
    },
  });
};

export { createSocketMiddleware };
