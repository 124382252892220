import React from "react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";

import { AppLocalizationProvider } from "./translation";
import App from "./component/App";
import MainCSS from "./component/MainCSS";
import { Router } from "./component/Router";

class Root extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.unsubscribeStore = props.store.subscribe(this.handleStoreChange);
    this.state = {
      locale: props.store.getState().session.locale,
    };
  }

  componentWillUnmount() {
    this.unsubscribeStore();
  }

  handleStoreChange = () => {
    const locale = this.props.store.getState().session.locale;
    this.setState({ locale });
  };

  render() {
    const { store } = this.props;
    const { locale } = this.state;
    return (
      <Provider store={store}>
        <AppLocalizationProvider key={locale} selectedLocale={locale}>
          <React.Fragment>
            <Router>
              <App />
            </Router>
            <MainCSS />
          </React.Fragment>
        </AppLocalizationProvider>
      </Provider>
    );
  }
}

export default Root;
