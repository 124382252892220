import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cx from "classnames";

import * as playerSelector from "../../state/player/selector";
import * as playerAction from "../../state/player/action";

import PlayerWaitingForGame from "./PlayerWaitingForGame";
import PlayerIntro from "./PlayerIntro";
import PlayerQuestion from "./PlayerQuestion";
import PlayerLeaderboard from "./PlayerLeaderboard";

const PlayerContent = ({ nickname, displayEvent, madeChoice, onChoice }) => {
  const displayEventType = displayEvent && displayEvent.type;
  return (
    <div
      className={cx(
        "player-content",
        {
          [`player-content--display-event-type--${displayEventType}`]: !!displayEventType,
        },
        { "player-content--display-event": !!displayEvent },
        { "player-content--not-display-event": !displayEvent }
      )}
    >
      {(() => {
        if (!displayEvent) {
          return <PlayerWaitingForGame />;
        }

        switch (displayEvent.type) {
          case "intro":
            return <PlayerIntro />;
          case "question":
            return (
              <PlayerQuestion
                nickname={nickname}
                displayEvent={displayEvent}
                madeChoice={madeChoice}
                onChoice={onChoice}
              />
            );
          case "leaderboard":
            return <PlayerLeaderboard />;
          default:
            return null;
        }
      })()}
    </div>
  );
};

const mapState = ({ player, session }) => ({
  displayEvent: playerSelector.getDisplayEvent(player),
  madeChoice: playerSelector.getMadeChoice(player),
  nickname: playerSelector.getNickname(player),
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      onChoice: playerAction.makeChoiceRequested,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(PlayerContent);
