import React from "react";
import cx from "classnames";

import HTMLContent from "../HTMLContent";

const QuestionText = ({ text }) => (
  <div
    className={cx(
      "screen-as-player-question",
      "screen-as-player-question--display--text"
    )}
  >
    {/* <PlayerMeta /> */}
    <div className="screen-as-player-question__content">
      <HTMLContent html={text} />
    </div>
  </div>
);

export default QuestionText;
