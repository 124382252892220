import { call, put } from "redux-saga/effects";

import channelPushSaga from "../../socket/util/channelPushSaga";
import * as action from "../action";
import getMasterChannelName from "./getMasterChannelName";

export default function* (event, payload) {
  const channel = yield call(getMasterChannelName);
  const { ok, error, timeout } = yield call(channelPushSaga, channel, event);

  let gameInfo;
  if (ok) {
    gameInfo = ok.game_info;
  } else if (error) {
    gameInfo = error.game_info;
  }
  if (gameInfo) {
    yield put(action.gameInfoReceived(gameInfo));
  }

  if (ok || error) {
    const { previous_possible, next_finishes_game } = ok || error;
    yield put(action.previousPossibleReceived(previous_possible));
    yield put(action.nextFinishesGameReceived(next_finishes_game));
  }

  return {
    ok,
    error,
    timeout,
    gameInfo,
  };
}
