import React from "react";
import { useSelector } from "react-redux";

import { getNickname } from "../../state/player";

import NicknamePage from "./NicknamePage";
import PlayerContent from "./PlayerContent";

const PlayerPage = () => {
  const nickname = useSelector(({ player }) => getNickname(player));

  if (!nickname) {
    return <NicknamePage />;
  }

  return (
    <div className="player-page">
      <PlayerContent />
    </div>
  );
};

export default PlayerPage;
