import React from "react";
import Hls from "hls.js";

class HLSSource extends React.Component {
  constructor(props) {
    super(props);
    this.hls = new Hls();
  }

  componentDidMount() {
    const { src, video } = this.props;
    if (Hls.isSupported()) {
      this.hls.loadSource(src);
      this.hls.attachMedia(video);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
    }
  }

  componentWillUnmount() {
    if (this.hls) {
      this.hls.destroy();
    }
  }

  render() {
    return (
      <source
        src={this.props.src}
        type={this.props.type || "application/x-mpegURL"}
      />
    );
  }
}

export default HLSSource;
