import React from "react";
import { useSelector, useDispatch } from "react-redux";
import useToggle from "react-use/lib/useToggle";

import {
  livestreamCreateRequested,
  livestreamStartRequested,
  livestreamStopRequested,
  livestreamDeleteRequested,
} from "../../state/master/action";

const StreamPage = () => {
  const livestream = useSelector(({ master: { livestream } }) => livestream);
  const dispatch = useDispatch();
  const [showRtmpKey, toggleRtmpKey] = useToggle(false);

  const handleCreateClick = () => dispatch(livestreamCreateRequested());
  const handleStartClick = () => dispatch(livestreamStartRequested());
  const handleStopClick = () => dispatch(livestreamStopRequested());
  const handleDeleteClick = () => dispatch(livestreamDeleteRequested());

  if (!livestream) {
    return (
      <div className="stream-page">
        <strong>Livestreams not enabled</strong>
      </div>
    );
  }

  const { state, input_rtmp, output_hls } = livestream;
  let rtmp;
  if (input_rtmp) {
    const parts = input_rtmp.split(/[/]/);
    const key = parts.pop();
    rtmp = { url: parts.join("/"), key };
  }

  return (
    <div className="stream-page">
      {state === "nonexistent" && (
        <button type="button" onClick={handleCreateClick}>
          Create livestream
        </button>
      )}
      {state === "creating" && (
        <button type="button" disabled>
          Creating livestream ...
        </button>
      )}
      {state === "idle" && (
        <>
          <button type="button" onClick={handleStartClick}>
            Start livestream
          </button>
          <button type="button" onClick={handleDeleteClick}>
            Delete livestream
          </button>
        </>
      )}
      {state === "starting" && (
        <button type="button" disabled>
          Starting livestream ...
        </button>
      )}
      {state === "started" && (
        <button type="button" onClick={handleStopClick}>
          Stop livestream
        </button>
      )}
      {state === "stopping" && (
        <button type="button" disabled>
          Stopping livestream ...
        </button>
      )}
      {state === "deleting" && (
        <button type="button" disabled>
          Deleting livestream ...
        </button>
      )}
      <hr />
      <h2>Stream information</h2>
      <table>
        <tr>
          <th>State</th>
          <td>{state}</td>
        </tr>
        {rtmp && (
          <>
            <tr>
              <th>RTMP url</th>
              <td>
                <input type="text" readOnly value={rtmp.url} />
              </td>
            </tr>
            <tr>
              <th>RTMP key</th>
              <td>
                <input
                  type={showRtmpKey ? "text" : "password"}
                  readOnly
                  value={rtmp.key}
                />
                <button onClick={toggleRtmpKey}>
                  {showRtmpKey ? "hide" : "show"}
                </button>
              </td>
            </tr>
          </>
        )}
        {output_hls && (
          <tr>
            <th>Output HLS</th>
            <td>
              <input type="text" value={output_hls} />
            </td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default StreamPage;
