import React from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import FormInputField from "../form/FormInputField";
import FormError from "../form/FormError";
import { required, handleSubmit } from "../form/util";

import { masterLoginFormSubmitted } from "../../state/master";

const MasterLoginForm = ({ handleSubmit, pristine, submitting, error }) => (
  <form onSubmit={handleSubmit} autoComplete="off">
    <FormError error={error} />
    <Field
      name="password"
      component={FormInputField}
      type="password"
      label="Password"
      validate={[required]}
    />
    <button type="submit" disabled={pristine || submitting}>
      Verstuur
    </button>
  </form>
);

export default reduxForm({
  form: "masterLoginForm",
  onSubmit: handleSubmit(({ password }, dispatch, { form }) =>
    dispatch(masterLoginFormSubmitted(form, password))
  ),
})(MasterLoginForm);
