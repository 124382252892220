import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

import { getLoggedIn } from "../../state/master";
import MasterLoginForm from "./MasterLoginForm";

type RootState = { master: any };

const MasterRoute: React.FC<any> = (props) => {
  const loggedIn = useSelector(({ master }: RootState) => getLoggedIn(master));

  if (!loggedIn) {
    const loginProps = {
      ...props,
      component: undefined,
      children: undefined,
      render: undefined,
    };
    return (
      <Route {...loginProps}>
        <div className="master-page">
          <MasterLoginForm />
        </div>
      </Route>
    );
  }

  return <Route {...props} />;
};

export default MasterRoute;
