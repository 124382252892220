import { put, take, race, call } from "redux-saga/effects";
import get from "lodash/get";

import * as socketAction from "../../socket/action";
import { getAuth } from "../../authStorage";

import * as action from "../action";
import { logoutSaga, logout } from "./logoutSaga";
import joinLobbySaga from "./joinLobbySaga";
import calculateServerTimeDifference from "./calculateServerTimeDifference";

export default function* restoreLoginSaga() {
  // Retrieve the token and abort if there isn't one
  const auth = yield call(getAuth);
  if (!auth) {
    return;
  }
  const { session_id: sessionId, token } = auth;

  // Show that we are logging in
  yield put(action.showLoggingIn());

  // Send a socket connect request with the persisted token
  yield put(socketAction.connectRequested({ params: { token } }));
  const { connectFailed } = yield race({
    connectSucceeded: take(socketAction.connectSucceeded),
    connectFailed: take(socketAction.connectFailed),
  });

  // On error, remove the (invalid?) token abort
  if (connectFailed) {
    yield call(logout);
    yield put(action.hideLoggingIn());
    return;
  }

  // Join the lobby channel and setup event listening
  // On error, report back to the user and abort
  const joinStartTime = new Date();
  const { ok: joinSucceeded, error: joinFailed } = yield call(
    joinLobbySaga,
    sessionId
  );
  if (joinFailed) {
    const errorResponse = joinFailed.payload.reason;
    yield put(action.loginFailed(errorResponse));
    return;
  }

  // Determine server time difference
  const joinEndTime = new Date();
  const serverTime = new Date(joinSucceeded.payload.server_time);
  const serverTimeDifference = yield call(
    calculateServerTimeDifference,
    joinStartTime,
    joinEndTime,
    serverTime
  );
  yield put(action.serverTimeDifferenceCalculated(serverTimeDifference));

  // Set custom CSS (if any)
  const {
    payload: { custom_css: customCSS, locale },
  } = joinSucceeded;
  if (customCSS) {
    yield put(action.customCSSAdded(customCSS));
  }
  if (locale) {
    yield put(action.localeSelected(locale));
  }
  yield put(
    action.setScreenAsPlayer(
      get(joinSucceeded, "payload.client.screen_as_player")
    )
  );

  // Report login success
  yield put(action.hideLoggingIn());
  yield put(action.loginSucceeded());

  // Wait for logout
  yield call(logoutSaga);
}
