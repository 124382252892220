import { takeEvery, put, all } from "redux-saga/effects";

import matchChannelEvent from "../../socket/util/matchChannelEvent";
import * as action from "../action";
import getChannelName from "./getChannelName";

const channelEventMatch = (event) => (action) =>
  matchChannelEvent(getChannelName(), event)(action);

export function* displayEventSagaWatcher() {
  yield takeEvery(channelEventMatch("display_event"), function* ({
    payload: { display_event },
  }) {
    yield put(action.displayEventReceived(display_event));
  });
}

export function* gameStartedWatcher() {
  yield takeEvery(channelEventMatch("game_started"), function* () {
    yield put(action.gameStarted());
  });
}

export function* gameResetWatcher() {
  yield takeEvery(channelEventMatch("game_reset"), function* () {
    yield put(action.gameReset());
  });
}

export function* gamePresenceWatcher() {
  yield takeEvery(channelEventMatch("player_presence"), function* ({
    payload,
  }) {
    yield put(action.gamePresenceReceived(payload.player_presence));
    yield put(action.gameTeamPresenceReceived(payload.team_presence));
  });
}

export function* gameLivestreamWatcher() {
  yield takeEvery(channelEventMatch("livestream_updated"), function* ({
    payload: { livestream },
  }) {
    yield put(action.livestreamUpdated(livestream));
  });
}

export default function* () {
  yield all([
    displayEventSagaWatcher(),
    gameStartedWatcher(),
    gameResetWatcher(),
    gamePresenceWatcher(),
    gameLivestreamWatcher(),
  ]);
}
