import asap from "asap";

export const required = (value) => !value && "This field is required";

export const handleSubmit = (handler) => (...args) => {
  asap(() => handler(...args));
  return new Promise(() => {});
};

export const makeSafeForCSS = (name) =>
  name.replace(/[^a-z0-9]/g, (s) => {
    const c = s.charCodeAt(0);
    if (c === 32) return "-";
    if (c >= 65 && c <= 90) return "_" + s.toLowerCase();
    return "__" + ("000" + c.toString(16)).slice(-4);
  });
