import { call } from "redux-saga/effects";

import { masterChannelPushSaga as pushSaga } from "./masterChannelPushSaga";

export function* countdownStartSaga() {
  yield call(pushSaga, "start_countdown");
}

export function* countdownStopSaga() {
  yield call(pushSaga, "stop_countdown");
}
