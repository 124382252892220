import { takeLeading, all, call, select } from "redux-saga/effects";

import channelJoinSaga from "../../socket/util/channelJoinSaga";
import { getJoinedChannels } from "../../socket/selector";

import * as action from "../action";

import getChannelName from "./getChannelName";
import gameSaga from "./gameSaga";

function* screenMountedSaga({ payload: nickname }) {
  const joinedChannels = yield select(({ socket }) =>
    getJoinedChannels(socket)
  );
  const channel = yield call(getChannelName);

  if (!joinedChannels.includes(channel)) {
    const params = {};
    const listenTo = [
      "game_started",
      "game_reset",
      "display_event",
      "player_presence",
      "livestream_updated",
    ];
    yield call(channelJoinSaga, channel, params, listenTo);

    // if (ok) {}
    // if (error) {}
  }
}

export default function* () {
  yield all([takeLeading(action.screenMounted, screenMountedSaga), gameSaga()]);
}
