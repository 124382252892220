import { createAction } from "redux-act";

import {
  setActionCorrelationId,
  createActionCorrelationIdMatcher,
} from "./util/actionCorrelationId";

export const connectRequested = createAction(
  "connect requested",
  (config = {}) => config
);
export const connectSucceeded = createAction("connect succeeded");
export const connectFailed = createAction("connect failed");

export const connectionDropped = createAction("connection dropped");

export const disconnectRequested = createAction("disconnect requested");
export const disconnectSucceeded = createAction("disconnect succeeded");

export const channelJoinRequested = createAction(
  "channel join requested",
  (channel, params = {}, events = []) => ({ channel, params, events })
);
export const channelJoinSucceeded = createAction(
  "channel join succeeded",
  (channel, data) => data,
  (channel, data) => ({ channel })
);
export const channelJoinFailed = createAction(
  "channel join failed",
  (channel, error) => error,
  (channel, error) => ({ channel })
);
export const channelJoinTimeout = createAction(
  "channel join timeout",
  (channel) => ({ reason: "timeout" }),
  (channel) => ({ channel })
);
export const joinedChannelsSet = createAction(
  "joined channels set",
  (channelNames) => channelNames
);

export const channelListenRequested = createAction(
  "channel listen requested",
  (channel, event) => event,
  (channel, event) => ({ channel })
);

export const channelEventReceived = createAction(
  "channel event received",
  (channel, event, data) => data,
  (channel, event, data) => ({ channel, event })
);

export const channelPushRequested = createAction(
  "channel push requested",
  (channel, event, data) => data,
  (channel, event, data) => ({ channel, event })
);
export const channelPushReceivedOk = createAction(
  "channel push received ok",
  (channel, event, data) => data,
  (channel, event, data) => ({ channel, event })
);
export const channelPushReceivedError = createAction(
  "channel push received error",
  (channel, event, error) => error,
  (channel, event, data) => ({ channel, event })
);
export const channelPushReceivedTimeout = createAction(
  "channel push received timeout",
  (channel, event) => undefined,
  (channel, event) => ({ channel, event })
);

/**
 * The functions below are predicate functions to be used
 * within e.g. a saga (with take effect) in order to match
 * an action with a certain correlationId.
 */
const matchPushOk = createActionCorrelationIdMatcher(channelPushReceivedOk);
const matchPushError = createActionCorrelationIdMatcher(
  channelPushReceivedError
);
const matchPushTimeout = createActionCorrelationIdMatcher(
  channelPushReceivedTimeout
);

/**
 * Create a channel push request.
 *
 * The channel push request object returned encapsulates a push request which
 * includes the channel push action (to be dispatched) together with
 * corresponding action matcher functions that match the push ok, push error
 * and push timeout actions from that specific channel push request.
 *
 * This is done by adding a correlationId to the action and look for the same
 * correlationId within the matcher functions.
 */
let correlationId = 0;
export const createChannelPushRequest = (...args) => {
  const action = channelPushRequested(...args);
  correlationId += 1;
  return {
    action: setActionCorrelationId(action, correlationId),
    matchOk: matchPushOk(correlationId),
    matchError: matchPushError(correlationId),
    matchTimeout: matchPushTimeout(correlationId),
  };
};
