import { createReducer } from "redux-act";
import update from "immutability-helper";

import * as action from "./action";

const initialState = {
  loggedIn: false,
  isStarted: false,
  isStarting: false,
  isResetting: false,
  isNextStarted: false,
  ended: undefined,

  counter: undefined,

  displayEvent: undefined,
  madeChoices: {},
  readyList: [],

  livestream: undefined,

  previousPossible: undefined,
  nextFinishesGame: undefined,
};

export default createReducer(
  {
    // login state
    [action.masterLoggedIn]: (state) =>
      update(state, { loggedIn: { $set: true } }),
    [action.masterLoggedOut]: (state) =>
      update(state, { loggedIn: { $set: false } }),

    // game info
    [action.gameInfoReceived]: (
      state,
      { started, ended, counter, livestream, ready_list }
    ) =>
      update(state, {
        isStarted: { $set: started },
        ended: { $set: ended },
        counter: { $set: counter },
        livestream: { $set: livestream },
      }),

    // previous possible
    [action.previousPossibleReceived]: (state, value) =>
      update(state, { previousPossible: { $set: value } }),

    // next finishes game
    [action.nextFinishesGameReceived]: (state, value) =>
      update(state, { nextFinishesGame: { $set: value } }),

    // display event
    [action.displayEventReceived]: (state, value) =>
      update(state, { displayEvent: { $set: value } }),

    // made choices
    [action.madeChoicesReceived]: (state, value) =>
      update(state, { madeChoices: { $set: value } }),

    // ready list
    [action.readyListReceived]: (state, value) =>
      update(state, { readyList: { $set: value } }),

    // starting
    [action.gameStartStarted]: (state) =>
      update(state, { isStarting: { $set: true }, isStarted: { $set: false } }),
    [action.gameStartSucceeded]: (state) =>
      update(state, {
        isStarting: { $set: false },
        isStarted: { $set: true },
      }),
    [action.gameStartFailed]: (state) =>
      update(state, { isStarting: { $set: false } }),

    // resetting
    [action.gameResetStarted]: (state) =>
      update(state, { isResetting: { $set: true } }),
    [action.gameResetSucceeded]: (state) =>
      update(state, {
        isResetting: { $set: false },
        isStarted: { $set: false },
      }),
    [action.gameResetFailed]: (state) =>
      update(state, { isResetting: { $set: false } }),

    // next
    [action.gameNextStarted]: (state) =>
      update(state, { isNextStarted: { $set: true } }),
    [action.gameNextSucceeded]: (state) =>
      update(state, { isNextStarted: { $set: false } }),
    [action.gameNextFailed]: (state) =>
      update(state, { isNextStarted: { $set: false } }),
  },
  initialState
);
