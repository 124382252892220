import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import get from "lodash/get";
import cx from "classnames";
import moment from "moment";

import { screenMounted } from "../../state/screen";
import * as screenSelector from "../../state/screen/selector";
import * as sessionSelector from "../../state/session/selector";
import { Localized } from "../../translation";

import Leaderboard from "../Leaderboard";

import LivestreamPlayer from "./LivestreamPlayer";
import ScreenWaitingForGame from "./ScreenWaitingForGame";
import ScreenIntro from "./ScreenIntro";
import ScreenQuestion from "./ScreenQuestion";

class ScreenPage extends React.Component {
  componentDidMount() {
    this.props.screenMounted();
  }
  render() {
    const {
      displayEvent,
      serverTimeDifference,
      presenceList,
      pin,
      scenarioName,
      countdown,
    } = this.props;
    const displayEventType = displayEvent && displayEvent.type;

    return (
      <div
        className={cx(
          "screen-page",
          {
            [`screen-page-display-event-type--${displayEventType}`]: !!displayEventType,
          },
          { "screen-page--display-event": !!displayEvent },
          { "screen-page--not-display-event": !displayEvent }
        )}
      >
        <LivestreamPlayer />
        <div className="screen-page__header">
          {displayEvent && (
            <div className="screen-page__pin">Pincode: {pin}</div>
          )}
          {countdown &&
            (() => {
              const [, remaining] = countdown;
              return (
                <div className="screen-page__countdown">
                  {remaining > 0 ? (
                    moment.utc(remaining * 1000).format("HH:mm:ss")
                  ) : (
                    <Localized id="screen-page__countdown">
                      Time is up!
                    </Localized>
                  )}
                </div>
              );
            })()}
        </div>
        <div className="screen-page__content">
          {(() => {
            if (!displayEvent) {
              return (
                <ScreenWaitingForGame
                  scenarioName={scenarioName}
                  pin={pin}
                  presenceList={presenceList}
                />
              );
            }

            let key = get(displayEvent, "payload.id");
            if (!key) {
              key = JSON.stringify(displayEvent);
            }

            switch (displayEvent.type) {
              case "intro":
                if (!displayEvent.payload) {
                  return null;
                }
                return <ScreenIntro key={key} displayEvent={displayEvent} />;
              case "question":
                return (
                  <ScreenQuestion
                    key={key}
                    displayEvent={displayEvent}
                    serverTimeDifference={serverTimeDifference}
                  />
                );
              case "leaderboard":
                return <Leaderboard key={key} displayEvent={displayEvent} />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({ session, screen }) => ({
  serverTimeDifference: sessionSelector.getServerTimeDifference(session),
  scenarioName: sessionSelector.getScenarioName(session),
  pin: sessionSelector.getPin(session),
  displayEvent: screenSelector.getDisplayEvent(screen),
  presenceList: screenSelector.getPresenceList(screen),
  countdown: sessionSelector.getCountdown(session),
});
const mapDispatch = (dispatch) =>
  bindActionCreators({ screenMounted }, dispatch);

export default connect(mapState, mapDispatch)(ScreenPage);
