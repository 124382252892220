import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";

import rootReducer from "./rootReducer";
import middleware, { sagaMiddleware } from "./middleware";
import rootSaga from "./rootSaga";

const configureStore = () => {
  const composeEnhancers = composeWithDevTools({
    name: process.env.REACT_APP_NAME,
  });
  const enhancer = composeEnhancers(middleware);

  const store = createStore(rootReducer, enhancer);

  if (module.hot && process.env.NODE_ENV === "development") {
    module.hot.accept("./rootReducer", () => {
      store.replaceReducer(rootReducer);
    });
  }

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
