import { createAction } from "redux-act";

export const screenMounted = createAction("screen mounted");

export const displayEventReceived = createAction(
  "display event received",
  (event) => event
);
export const gameStarted = createAction("game started");
export const gameReset = createAction("game was reset");

export const gamePresenceReceived = createAction(
  "game presence received",
  (list) => list
);

export const gameTeamPresenceReceived = createAction(
  "game team presence received",
  (list) => list
);

export const livestreamUpdated = createAction(
  "livestream updated",
  (livestream) => livestream
);
