import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import cx from "classnames";
import {
  AiFillFile,
  AiFillFilePdf,
  AiFillSound,
  AiFillVideoCamera,
} from "react-icons/ai";
import { DefaultPlayer as Video } from "react-html5video";

import HTMLContent from "../HTMLContent";

const ObjectModal = ({
  show,
  handleClose,
  objects,
  classPrefix,
  header,
  intro,
}) => {
  const [showItem, setShowItem] = useState(null);

  const handleItemClose = () => setShowItem(null);
  const handleItemClick = (item) => {
    if (item.type === "pdf") {
      window.open(item.content, "_blank", "fullscreen=yes");
      return;
    }

    setShowItem(item);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName={`${classPrefix}modal-dialog`}
        scrollable
      >
        <Modal.Header closeButton>{header}</Modal.Header>
        <Modal.Body>
          <p>{intro}</p>
          {objects.length > 0 &&
            [...objects].reverse().map((item, index) => (
              <div
                key={index}
                className={cx(
                  `${classPrefix}item-link`,
                  `${classPrefix}item-link--type--${item.type}`
                )}
                onClick={() => handleItemClick(item)}
              >
                <div className={`${classPrefix}item-link__name`}>
                  {item.name}
                </div>
                <div className={`${classPrefix}item-link__icon`}>
                  {(() => {
                    switch (item.type) {
                      case "pdf":
                        return <AiFillFilePdf />;
                      case "html":
                        return <AiFillFile />;
                      case "audio":
                        return <AiFillSound />;
                      case "video":
                        return <AiFillVideoCamera />;
                      default:
                        return null;
                    }
                  })()}
                </div>
                {item.description && (
                  <div className={`${classPrefix}item-link__description`}>
                    {item.description}
                  </div>
                )}
              </div>
            ))}
        </Modal.Body>
      </Modal>
      <Modal
        show={!!showItem}
        onHide={handleItemClose}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
        dialogClassName={`${classPrefix}item-modal-dialog`}
        backdropClassName={`${classPrefix}item-modal-backdrop`}
      >
        {showItem && (
          <>
            <Modal.Header closeButton>{showItem.name}</Modal.Header>
            <Modal.Body>
              <div className={`${classPrefix}repository-item-modal__content`}>
                {(() => {
                  switch (showItem.type) {
                    case "html":
                      return <HTMLContent html={showItem.content} />;
                    case "audio":
                      return (
                        <audio controls>
                          <source src={showItem.content} />
                        </audio>
                      );
                    case "video":
                      return (
                        <Video
                          autoPlay
                          controls={["Seek", "Time", "Volume", "Fullscreen"]}
                        >
                          <source src={showItem.content} />
                        </Video>
                      );
                    default:
                      return null;
                  }
                })()}
              </div>
              {showItem.description && (
                <div className={`${classPrefix}item-modal__description`}>
                  <p>{showItem.description}</p>
                </div>
              )}
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

export default ObjectModal;
