import { createReducer } from "redux-act";
import update from "immutability-helper";

import * as action from "./action";

const initialState = {
  nickname: undefined,
  playername: undefined,
  isTeamLeader: undefined,
  displayEvent: undefined,
  isReady: undefined,
  madeChoice: undefined,
  recentQuestionId: undefined,
  score: undefined,
  rank: undefined,
  metrics: undefined,
  openRepository: false,
  repository: [],
  openInventory: false,
  inventory: [],
};

export default createReducer(
  {
    [action.nicknameSet]: (state, payload) =>
      update(state, { nickname: { $set: payload } }),
    [action.nicknameUnset]: (state, payload) =>
      update(state, { nickname: { $set: undefined } }),

    [action.playernameSet]: (state, payload) =>
      update(state, { playername: { $set: payload } }),
    [action.playernameUnset]: (state, payload) =>
      update(state, { playername: { $set: undefined } }),

    [action.displayEventReceived]: (state, payload) => {
      if (
        payload &&
        state.displayEvent &&
        payload.payload.id !== state.displayEvent.payload.id
      ) {
        state = update(state, {
          openRepository: { $set: false },
          openInventory: { $set: false },
        });
      }
      if (payload && payload.type === "question") {
        const questionId = payload.payload.id;
        state = update(state, { recentQuestionId: { $set: questionId } });
      }
      if (payload && payload.type !== "question") {
        state = update(state, { madeChoice: { $set: undefined } });
      }
      return update(state, { displayEvent: { $set: payload } });
    },

    [action.scoreUpdated]: (state, payload) =>
      update(state, {
        score: { $set: payload.points },
        rank: { $set: payload.rank },
        metrics: { $set: payload.metrics },
        repository: { $set: payload.repository },
        inventory: { $set: payload.inventory },
      }),

    [action.gameStarted]: (state) =>
      update(state, {
        displayEvent: { $set: undefined },
        madeChoice: { $set: undefined },
        score: { $set: undefined },
        rank: { $set: undefined },
        metrics: { $set: undefined },
        isReady: { $set: undefined },
        openRepository: { $set: false },
        repository: { $set: [] },
        openInventory: { $set: false },
        inventory: { $set: [] },
      }),
    [action.gameReset]: (state) =>
      update(state, {
        displayEvent: { $set: undefined },
        madeChoice: { $set: undefined },
        score: { $set: undefined },
        rank: { $set: undefined },
        metrics: { $set: undefined },
        isReady: { $set: undefined },
        openRepository: { $set: false },
        repository: { $set: [] },
        openInventory: { $set: false },
        inventory: { $set: [] },
      }),

    [action.choiceUpdated]: (state, payload) =>
      update(state, {
        madeChoice: {
          $set: payload !== undefined && payload !== null ? payload : undefined,
        },
      }),
    [action.clearMadeChoice]: (state) =>
      update(state, { madeChoice: { $set: undefined } }),
    [action.isTeamLeaderUpdated]: (state, payload) =>
      update(state, { isTeamLeader: { $set: payload } }),

    [action.isReadyUpdated]: (state, payload) =>
      update(state, { isReady: { $set: payload } }),

    [action.openRepositoryRequested]: (state) =>
      update(state, { openRepository: { $set: true } }),
    [action.closeRepositoryRequested]: (state) =>
      update(state, { openRepository: { $set: false } }),

    [action.openInventoryRequested]: (state) =>
      update(state, { openInventory: { $set: true } }),
    [action.closeInventoryRequested]: (state) =>
      update(state, { openInventory: { $set: false } }),
  },
  initialState
);
