import { put, take, race } from "redux-saga/effects";

import * as socketAction from "../action";

export default function* channelPushSaga(channel, event, payload) {
  const pushRequest = socketAction.createChannelPushRequest(
    channel,
    event,
    payload
  );
  yield put(pushRequest.action);

  const { pushOk, pushError, pushTimeout } = yield race({
    pushOk: take(pushRequest.matchOk),
    pushError: take(pushRequest.matchError),
    pushTimeout: take(pushRequest.matchTimeout),
  });

  return {
    ok: pushOk && pushOk.payload,
    error: pushError && pushError.payload,
    timeout: !!pushTimeout,
  };
}
