import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";

import { getCustomCSS } from "../state/session";

class MainCSS extends React.Component {
  constructor(props) {
    super(props);
    this.overrideCSSRef = React.createRef();
    this.savedCSSNodes = undefined;
    this.state = {
      overrideLoaded: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { customCSS } = this.props;
    let { overrideLoaded } = this.state;
    // const {savedCSSNodes} = this.state
    const overrideCSSNode = this.overrideCSSRef.current;

    // Reset override loading state if needed
    if (prevProps.customCSS !== customCSS && overrideLoaded) {
      overrideLoaded = false;
      this.setState({ overrideLoaded });
    }

    // In case there is custom CSS to be rendered and we
    // did not save/remove the original CSS yet, do so.
    // (of course exclude the override css node)
    if (customCSS && overrideLoaded && !this.savedCSSNodes) {
      // get all CSS nodes that are currently defined in the document head
      let nodes = [
        ...document.querySelectorAll(
          "head > style, head > link[rel=stylesheet]"
        ),
      ];
      nodes = nodes.filter((node) => node !== overrideCSSNode);

      // for each of those nodes, replace the original one with an empty
      // style node, keeping track of both (for later restoration)
      const newSavedCSSNodes = [];
      nodes.forEach((node) => {
        const originalNode = node;
        const placeholderNode = document.createElement("style");
        originalNode.parentElement.replaceChild(placeholderNode, originalNode);
        newSavedCSSNodes.push({ originalNode, placeholderNode });
      });

      this.savedCSSNodes = newSavedCSSNodes;

      // In case there is no custom CSS but we have
      // overridden CSS, restore it.
    } else if (!customCSS && this.savedCSSNodes) {
      // for each of those nodes, replace the original one with an empty
      // style node, keeping track of both (for later restoration)
      this.savedCSSNodes.forEach(({ originalNode, placeholderNode }) => {
        placeholderNode.parentElement.replaceChild(
          originalNode,
          placeholderNode
        );
      });
      this.savedCSSNodes = undefined;
    }
  }

  handleOverrideLoaded = (e) => {
    this.setState({ overrideLoaded: true });
  };

  render() {
    const { customCSS } = this.props;

    if (!customCSS) {
      return null;
    }

    return ReactDOM.createPortal(
      <link
        type="text/css"
        rel="stylesheet"
        href={customCSS}
        onLoad={this.handleOverrideLoaded}
        ref={this.overrideCSSRef}
      />,
      document.head
    );
  }
}

const mapStateToProps = ({ session }) => ({
  customCSS: getCustomCSS(session),
});

export default connect(mapStateToProps)(MainCSS);
