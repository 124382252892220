import React from "react";
import cx from "classnames";
import get from "lodash/get";
import identity from "lodash/identity";

import Metric from "./Metric";

const Leaderboard = ({ displayEvent, renderNickname = identity }) => {
  const {
    payload: { statistics },
  } = displayEvent;

  let players = Object.keys(statistics.players).reduce((acc, nickname) => {
    return [...acc, { nickname, points: statistics.players[nickname] }];
  }, []);
  players.sort((a, b) => b.points - a.points);

  const visibleMetrics = statistics.visible_metrics;
  const showableMetrics = statistics.available_metrics.filter((x) =>
    visibleMetrics.includes(x.id)
  );

  players = players.map((player) => ({
    ...player,
    metrics: showableMetrics.map((metric) => ({
      ...metric,
      value: get(statistics, ["metrics", player.nickname, metric.id], 0),
    })),
  }));

  const highestScore = players[0] && players[0].points;

  return (
    <div className="leaderboard-table">
      <table className="leaderboard-table__table">
        <thead>
          <tr>
            <td colSpan={2}></td>
            {visibleMetrics.includes("score") && (
              <td className="leaderboard-table__metric-name">Points</td>
            )}
            {showableMetrics.map((metric) => (
              <td
                className={cx(
                  "leaderboard-table__metric-name",
                  `leaderboard-table__metric-name--${metric.id}`
                )}
              >
                {metric.name}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {players.map(({ nickname, points, metrics }, index) => {
            const leading = points === highestScore;
            const rank = index + 1;
            return (
              <>
                <tr
                  key={`${nickname}${index}`}
                  className={cx(
                    "leaderboard-table__row",
                    { "leaderboard-table__row--leading": leading },
                    { "leaderboard-table__row--not-leading": !leading }
                  )}
                >
                  <td className="leaderboard-table__row__rank">{rank}</td>
                  <td className="leaderboard-table__row__nickname">
                    {renderNickname(nickname)}
                  </td>
                  {visibleMetrics.includes("score") && (
                    <td className="leaderboard-table__row__points">
                      <Metric
                        metric={{ id: "points", name: "Points", value: points }}
                      />
                    </td>
                  )}
                  {metrics.map((metric, index) => (
                    <td
                      key={index}
                      className={cx(
                        "leaderboard-table__row__metric",
                        `leaderboard-table__row__metric--${metric.id}`
                      )}
                    >
                      <Metric metric={metric} />
                    </td>
                  ))}
                </tr>
                <tr className="leaderboard-table__spacer-row" />
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
