import { createReducer } from "redux-act";
import update from "immutability-helper";

import * as action from "./action";

const initialState = {
  connecting: false,
  connected: false,
  reconnecting: false,
  joinedChannels: [],
};

export default createReducer(
  {
    [action.connectRequested]: (state) =>
      update(state, {
        connected: { $set: false },
        connecting: { $set: true },
        reconnecting: { $set: false },
      }),
    [action.connectSucceeded]: (state) =>
      update(state, {
        connected: { $set: true },
        connecting: { $set: false },
        reconnecting: { $set: false },
      }),
    [action.connectFailed]: (state) =>
      update(state, {
        connected: { $set: false },
        connecting: { $set: false },
        reconnecting: { $set: false },
      }),
    [action.connectionDropped]: (state) =>
      update(state, {
        connected: { $set: false },
        connecting: { $set: false },
        reconnecting: { $set: true },
      }),
    [action.joinedChannelsSet]: (state, payload) =>
      update(state, {
        joinedChannels: { $set: payload },
      }),
  },
  initialState
);
