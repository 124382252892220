import React from "react";

import MasterContent from "./MasterContent";

const MasterPage = () => (
  <div className="master-page">
    <MasterContent />
  </div>
);

export default MasterPage;
