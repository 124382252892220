import { call, delay } from "redux-saga/effects";

import { getSessionId, getMasterPassword } from "../../authStorage";

import masterLoginSaga from "./masterLoginSaga";

export default function* () {
  const sessionId = yield call(getSessionId);
  if (!sessionId) {
    return;
  }

  // FIXME: introduced due to race in login ...
  //        restructure saga's regarding socket login
  //        or use component lifecycle hooks for firing
  //        events
  yield delay(1000);

  const password = yield call(getMasterPassword);
  if (password) {
    yield call(masterLoginSaga, password);
  }
}
