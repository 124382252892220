import React from "react";

import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";

import { makeSafeForCSS } from "./util";

class FormFieldWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputId: props.id || `form-item__input--${uuidv4()}`,
    };
  }

  render() {
    const {
      input,
      input: { name },
      label,
      type,
      className,
      meta: { pristine, active, touched, dirty, error, warning },
      children,
    } = this.props;
    const { inputId } = this.state;

    return (
      <div
        className={classnames(
          "form-item",
          `form-item--name--${makeSafeForCSS(name)}`,
          `form-item--type--${type}`,
          { "form-item--state--pristine": pristine },
          { "form-item--state--active": active },
          { "form-item--state--touched": touched },
          { "form-item--state--dirty": dirty },
          { "form-item--state--checked": input.checked },
          { "form-item--state--error": touched && !active && error },
          { "form-item--state--warning": touched && !active && warning },
          className
        )}
      >
        <label htmlFor={inputId} className="form-item__label">
          {label}
        </label>
        <div className="form-item__field">
          {children({ ...this.props, inputId })}
        </div>
        {touched && !active && (error || warning) && (
          <div
            className={classnames("form-item__message", {
              "form-item__message--error": error,
              "form-item__message--warning": warning,
            })}
          >
            {error || warning}
          </div>
        )}
      </div>
    );
  }
}

export default FormFieldWrapper;
