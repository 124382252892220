import React from "react";
import useMount from "react-use/lib/useMount";
import cx from "classnames";
import get from "lodash/get";

import { Localized } from "../../translation";
import { showToast } from "../../toast";

import HTMLContent from "../HTMLContent";

const ResultDisplay = ({ choices, madeChoice, statistics, nickname }) => {
  useMount(() => {
    const choiceIndex = madeChoice;
    const choice = choices[choiceIndex];

    if (choice === undefined) {
      showToast(
        <div className="player-info">
          <Localized id="player-question-too-late">
            player-question-too-late
          </Localized>
        </div>,
        { autoClose: 3000 }
      );
      return null;
    }
    const pointsReceived = choice.points;
    const choiceHasPoints = pointsReceived !== undefined;
    const bonusPointsReceived = get(statistics, ["time_bonuses", nickname], 0);
    showToast(
      <div
        className={cx(
          "player-made-choices",
          {
            "player-made-choices--correct": choiceHasPoints && pointsReceived,
          },
          {
            "player-made-choices--not-correct":
              choiceHasPoints && !pointsReceived,
          }
        )}
      >
        <div
          className={cx(
            "player-made-choices__choice",
            `player-made-choices__choice--index--${choiceIndex}`
          )}
        >
          {choice.content}
        </div>

        {choice.message ? (
          <div className={cx("player-made-choices__message")}>
            {choice.message}
            {choiceHasPoints && (
              <div className="player-made-choices__points">
                {pointsReceived}
                {pointsReceived && bonusPointsReceived
                  ? ` (+${bonusPointsReceived} bonus)`
                  : null}
              </div>
            )}
          </div>
        ) : (
          choiceHasPoints && (
            <div className={cx("player-made-choices__message")}>
              <Localized
                id="player-question-correct-incorrect"
                $status={pointsReceived ? "correct" : "incorrect"}
              >
                player-question-correct-incorrect
              </Localized>

              <div className="player-made-choices__points">
                {pointsReceived}
                {pointsReceived && bonusPointsReceived
                  ? ` (+${bonusPointsReceived} bonus)`
                  : null}
              </div>
            </div>
          )
        )}
      </div>,
      { autoClose: 3000 }
    );
  });

  return null;
};

const QuestionResults = ({
  text,
  choices,
  madeChoice,
  screenText,
  statistics,
  nickname,
}) => (
  <div
    className={cx(
      "screen-as-player-question",
      "screen-as-player-question--display--statistics",
      `screen-as-player-question--choices-count--${choices.length}`
    )}
  >
    <ResultDisplay {...{ choices, madeChoice, statistics, nickname }} />
    <div className="screen-as-player-question__content">
      <HTMLContent html={text} />
    </div>
    {screenText ? (
      <div className="screen-as-player-question__content">
        <HTMLContent html={screenText} />
      </div>
    ) : (
      <div className="screen-as-player-question__choices">
        {choices.map((choice, index) => {
          const choiceHasPoints = choice.points !== undefined;
          const pointsReceived = choice.points;
          const showCorrect = choiceHasPoints && pointsReceived;
          const showIncorrect = choiceHasPoints && !pointsReceived;
          const chosenCount = statistics.choices[index];
          return (
            <div
              className={cx(
                "screen-as-player-question__choice",
                `screen-as-player-question__choice--index--${index}`,
                { "screen-as-player-question__choice--correct": showCorrect },
                {
                  "screen-as-player-question__choice--not-correct": showIncorrect,
                }
              )}
              key={index}
            >
              <HTMLContent html={choice.content} />

              {(showCorrect || showIncorrect) && (
                <div className="screen-as-player-question__choice__points">
                  <div className="screen-as-player-question__choice__points__stats">
                    {chosenCount}
                  </div>
                  <div className="screen-as-player-question__choice__points__received">
                    {pointsReceived}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    )}
  </div>
);

export default QuestionResults;
