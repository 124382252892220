import React from "react";
import { reduxForm } from "redux-form";
import { Field } from "redux-form";

import { Localized } from "../../translation";

import { nicknameFormSubmitted } from "../../state/player";

import { FormInputField, FormError, required, handleSubmit } from "../form";

const NicknameForm = ({ handleSubmit, pristine, submitting, error }) => (
  <form onSubmit={handleSubmit} autoComplete="off" className="login-form">
    <div className="login-form__fields">
      {error && <FormError error={error} />}
      <Field
        name="nickname"
        component={FormInputField}
        type="text"
        label="Nickname"
        validate={[required]}
      />
    </div>
    <div className="login-form__submit">
      <button type="submit" disabled={pristine || submitting}>
        <Localized id="form-submit">Submit</Localized>
      </button>
    </div>
  </form>
);

const NicknameFormContainer = reduxForm({
  form: "nicknameForm",
  onSubmit: handleSubmit(({ nickname }, dispatch, { form }) =>
    dispatch(nicknameFormSubmitted(form, nickname))
  ),
})(NicknameForm);

const NicknamePage = () => (
  <div className="login-page">
    <div className="login-page__header">
      <Localized id="login-page-header">
        <h1>Welcome</h1>
      </Localized>
    </div>
    <div className="login-page__form">
      <NicknameFormContainer />
    </div>
  </div>
);

export default NicknamePage;
