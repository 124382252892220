import { call } from "redux-saga/effects";

import channelJoinSaga from "../../socket/util/channelJoinSaga";

export default function* (sessionId) {
  const channel = `lobby:${sessionId}`;
  const params = {};
  const listenTo = [
    "game_info",
    "game_started",
    "game_reset",
    "countdown_updated",
  ];
  return yield call(channelJoinSaga, channel, params, listenTo);
}
