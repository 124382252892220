import React from "react";

const HTMLContent = ({ html }) => (
  <div
    className="HTMLContent"
    style={{ display: "inline" }}
    dangerouslySetInnerHTML={{ __html: html }}
  />
);

export default HTMLContent;
