import React, { useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getLoggingIn, getLoggedIn, getScreenAsPlayer } from "../state/session";
import { getReconnecting } from "../state/socket";

import { Routes, RoutesForScreenAsPlayer } from "./Router";

import AppReconnecting from "./AppReconnecting";
import LoginPage from "./LoginPage";

const App = () => {
  const loggingIn = useSelector(({ session }) => getLoggingIn(session));
  const screenAsPlayer = useSelector(({ session }) =>
    getScreenAsPlayer(session)
  );
  const loggedIn = useSelector(({ session }) => getLoggedIn(session));
  const reconnecting = useSelector(({ socket }) => getReconnecting(socket));

  const [initiallyLoggingIn, setInitiallyLoggingIn] = useState(true);

  if (initiallyLoggingIn && !loggingIn) {
    setInitiallyLoggingIn(false);
  }

  return (
    <div
      className={cx(
        "app",
        { "app--logging-in": loggingIn },
        { "app--initially-logging-in": initiallyLoggingIn },
        { "app--reconnecting": reconnecting }
      )}
    >
      {(() => {
        if (!loggedIn && initiallyLoggingIn) {
          return null;
        }

        if (!loggedIn) {
          return <LoginPage />;
        }

        if (reconnecting) {
          return <AppReconnecting />;
        }

        return (
          <React.Fragment>
            {screenAsPlayer ? <RoutesForScreenAsPlayer /> : <Routes />}
          </React.Fragment>
        );
      })()}

      <ToastContainer />
    </div>
  );
};

export default App;
