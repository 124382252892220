import React from "react";
import { QRCode } from "react-qrcode";

import { Localized } from "../../translation";
import { urlForPin } from "../../util/gameInfo";

const ScreenWaitingForGame = ({ scenarioName, pin, presenceList }) => {
  const { url, urlWithPin } = urlForPin(pin);
  return (
    <div className="screen-waiting-for-game">
      <div className="screen-waiting-for-game__header">
        <Localized id="screen-waiting-for-game-header">
          <h1>Welcome</h1>
        </Localized>
      </div>
      <div className="screen-waiting-for-game__scenario-name">
        {scenarioName}
      </div>
      <div className="screen-waiting-for-game__pin">
        <Localized id="screen-waiting-for-game-pin" $pin={pin}>
          {"Pincode: {$pin}"}
        </Localized>
      </div>
      <div className="screen-waiting-for-game__url">
        <Localized id="screen-waiting-for-game-url" $url={url}>
          {"URL: {$url}"}
        </Localized>
      </div>
      <div className="screen-waiting-for-game__qr">
        <QRCode
          value={urlWithPin}
          errorCorrectionLevel="H"
          margin={1}
          scale={5}
        />
      </div>
      <div className="screen-waiting-for-game__presence">
        {presenceList.length > 0 ? (
          <React.Fragment>
            <Localized
              id="screen-waiting-for-game-presence-header"
              $amount={presenceList.length}
            >
              <h2>{"Present ({$amount}):"}</h2>
            </Localized>
            <p>
              {presenceList.map((nickname, index) => (
                <React.Fragment key={`${nickname}-${index}`}>
                  {index !== 0 && (
                    <span className="screen-waiting-for-game__separator">
                      {" | "}
                    </span>
                  )}
                  <span className="screen-waiting-for-game__nickname">
                    {nickname}
                  </span>
                </React.Fragment>
              ))}
            </p>
          </React.Fragment>
        ) : (
          <Localized id="screen-waiting-for-game-no-players">
            <p>No players present yet ...</p>
          </Localized>
        )}
      </div>
    </div>
  );
};

export default ScreenWaitingForGame;
