import { put, take, race } from "redux-saga/effects";

import * as socketAction from "../action";

const matchChannelAction = (actionCreator) => (channel) => (action) => {
  const { type, meta: { channel: actionChannel } = {} } = action;
  const actionCreatorString = actionCreator.toString();
  const actionCreatorMatches = type === actionCreatorString;
  const actionChannelMatches = channel === actionChannel;
  return actionCreatorMatches && actionChannelMatches;
};

const createOkMatcher = matchChannelAction(socketAction.channelJoinSucceeded);
const createErrorMatcher = matchChannelAction(socketAction.channelJoinFailed);
const createTimeoutMatcher = matchChannelAction(
  socketAction.channelJoinTimeout
);

export default function* channelJoinSaga(channel, params, events = []) {
  yield put(socketAction.channelJoinRequested(channel, params, events));

  return yield race({
    ok: take(createOkMatcher(channel)),
    error: take(createErrorMatcher(channel)),
    timeout: take(createTimeoutMatcher(channel)),
  });
}
