import React from "react";
import qs from "qs";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Field, change } from "redux-form";

import { withRouter } from "./Router";

import { Localized } from "../translation";
import { loginFormSubmitted } from "../state/session";

import FormInputField from "./form/FormInputField";
import FormError from "./form/FormError";
import { required, handleSubmit } from "./form/util";

const LoginForm = ({ handleSubmit, pristine, submitting, error }) => (
  <form onSubmit={handleSubmit} autoComplete="off" className="login-form">
    <div className="login-form__fields">
      {error && <FormError error={error} />}
      <Field
        name="pin"
        component={FormInputField}
        type="text"
        label="Pincode"
        validate={[required]}
      />
    </div>
    <div className="login-form__submit">
      <button type="submit" tabIndex={0} disabled={pristine || submitting}>
        <Localized id="form-next">Next</Localized>
      </button>
    </div>
  </form>
);

const LoginFormContainer = reduxForm({
  form: "login",
  onSubmit: handleSubmit(({ pin }, dispatch, { form }) =>
    dispatch(loginFormSubmitted(form, pin))
  ),
})(LoginForm);

class LoginPage extends React.Component {
  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    if (query.pin) {
      this.props.change("login", "pin", query.pin);
    }
  }
  render() {
    return (
      <div className="login-page">
        <div className="login-page__header">
          <Localized id="login-page-header">
            <h1>Welcome</h1>
          </Localized>
        </div>
        <div className="login-page__form">
          <LoginFormContainer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ change }, dispatch);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
