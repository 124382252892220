import React from "react";
import cx from "classnames";
import get from "lodash/get";

import { Localized } from "../../translation";

import PlayerMeta from "./PlayerMeta";
import PlayerInfo from "./PlayerInfo";
import PlayerButtons from "./PlayerButtons";

const PlayerQuestion = ({ nickname, displayEvent, madeChoice, onChoice }) => {
  const {
    payload: { choices, statistics },
  } = displayEvent;

  /**
   * In case the choices are not available yet just
   * render the text
   */
  if (!choices) {
    return (
      <>
        <PlayerMeta />
        <Localized id="player-question-text">
          <PlayerInfo>player-question-text</PlayerInfo>
        </Localized>
      </>
    );
  }

  /**
   * When there are no statistics, display the choice buttons
   */
  if (!statistics) {
    return (
      <>
        <PlayerMeta />
        <PlayerButtons
          choices={choices}
          madeChoice={madeChoice}
          onChoice={onChoice}
        />
      </>
    );
  }

  /**
   * Otherwise we have statistics and therefore should
   * display the made choices/results
   */
  const choiceIndex = madeChoice;
  const choice = choices[choiceIndex];
  if (choice === undefined) {
    return (
      <>
        <PlayerMeta />
        <Localized id="player-question-too-late">
          <PlayerInfo>player-question-too-late</PlayerInfo>
        </Localized>
      </>
    );
  }
  const pointsReceived = choice.points;
  const choiceHasPoints = pointsReceived !== undefined;
  const bonusPointsReceived = get(statistics, ["time_bonuses", nickname], 0);
  return (
    <div
      className={cx(
        "player-made-choices",
        { "player-made-choices--correct": choiceHasPoints && pointsReceived },
        {
          "player-made-choices--not-correct":
            choiceHasPoints && !pointsReceived,
        }
      )}
    >
      <div
        className={cx(
          "player-made-choices__choice",
          `player-made-choices__choice--index--${choiceIndex}`
        )}
      >
        {choice.content}
      </div>

      {choiceHasPoints && (
        <div className={cx("player-made-choices__message")}>
          <Localized
            id="player-question-correct-incorrect"
            $status={pointsReceived ? "correct" : "incorrect"}
          >
            player-question-correct-incorrect
          </Localized>

          <div className="player-made-choices__points">
            {pointsReceived}
            {pointsReceived && bonusPointsReceived
              ? ` (+${bonusPointsReceived} bonus)`
              : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlayerQuestion;
