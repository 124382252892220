import { takeLeading, all, call, put } from "redux-saga/effects";

import {
  getSessionId,
  setNickname,
  getNickname,
  setPlayername,
  getPlayername,
} from "../../authStorage";
import channelJoinSaga from "../../socket/util/channelJoinSaga";

import * as action from "../action";

import getChannelName from "./getChannelName";
import gameSaga from "./gameSaga";

function* nicknameFormSubmittedSaga({ payload: { form, nickname } }) {
  yield put(action.nicknameSetRequested(nickname));
}

function* restoreNicknameSaga() {
  const sessionId = yield call(getSessionId);
  if (!sessionId) {
    return;
  }

  const nickname = yield call(getNickname);
  if (nickname) {
    yield put(action.nicknameSetRequested(nickname));
  }

  const playername = yield call(getPlayername);
  if (playername) {
    yield put(action.playernameSet(playername));
  }
}

function* nicknameSetRequestedSaga({ payload: nickname }) {
  yield call(setNickname, nickname);

  if (!nickname) {
    window.document.location.reload();
    return;
  }

  yield put(action.nicknameSet(nickname));

  const playername = yield call(getPlayername);

  const channel = getChannelName();
  const params = { nickname, playername };
  const listenTo = [
    "game_started",
    "game_reset",
    "display_event",
    "score_updated",
    "choice_updated",
    "is_ready_updated",
    "team_info",
    "message",
  ];
  yield call(channelJoinSaga, channel, params, listenTo);
}

function* nicknameUnsetRequestedSaga() {
  yield call(setNickname);
  yield put(action.nicknameUnset());
}

function* playernameFormSubmittedSaga({ payload: { form, playername } }) {
  yield call(setPlayername, playername);
  yield put(action.playernameSet(playername));
}

function* playernameUnsetRequestedSaga() {
  yield call(setPlayername);
  yield put(action.playernameUnset());
}

export default function* () {
  yield all([
    takeLeading(action.nicknameFormSubmitted, nicknameFormSubmittedSaga),
    takeLeading(action.nicknameSetRequested, nicknameSetRequestedSaga),
    takeLeading(action.nicknameUnsetRequested, nicknameUnsetRequestedSaga),
    takeLeading(action.playernameFormSubmitted, playernameFormSubmittedSaga),
    takeLeading(action.playernameUnsetRequested, playernameUnsetRequestedSaga),
    restoreNicknameSaga(),
    gameSaga(),
  ]);
}
