import { call } from "redux-saga/effects";

import channelPushSaga from "../../socket/util/channelPushSaga";
import getMasterChannelName from "./getMasterChannelName";

export function* masterChannelPushSaga(event) {
  const channel = yield call(getMasterChannelName);
  const { ok, error, timeout } = yield call(channelPushSaga, channel, event);
  return { ok, error, timeout };
}
