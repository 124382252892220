import { call, put } from "redux-saga/effects";

import * as action from "../action";

import masterChannelOperationSaga from "./masterChannelOperationSaga";

export default function* () {
  yield put(action.gameNextStarted());

  const { ok } = yield call(masterChannelOperationSaga, "next");

  if (ok) {
    const { made_choices, ready_list } = ok;
    yield put(action.gameNextSucceeded());
    yield put(action.madeChoicesReceived(made_choices));
    yield put(action.readyListReceived(ready_list));
  } else {
    yield put(action.gameNextFailed());
  }
}
