import { call, put } from "redux-saga/effects";

import { setMasterPassword } from "../../authStorage";
import channelJoinSaga from "../../socket/util/channelJoinSaga";

import getMasterChannelName from "./getMasterChannelName";
import * as action from "../action";

export default function* masterLoginSaga(password) {
  // Try to join the master channel with the password provided
  const channel = yield call(getMasterChannelName);
  const params = { password };
  const listenTo = [
    "game_info",
    "made_choices_updated",
    "ready_list_updated",
    "display_event",
  ];
  const { ok, error } = yield call(channelJoinSaga, channel, params, listenTo);
  if (error) {
    yield call(setMasterPassword);
  }
  if (ok) {
    yield call(setMasterPassword, password);
    yield put(action.masterLoggedIn());
  }
  return { ok, error };
}
