import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";

import { Localized } from "../../translation";

import {
  nicknameFormSubmitted,
  nicknameSetRequested,
} from "../../state/player";
import { getPlayers, getPin } from "../../state/session";
import { urlForPin } from "../../util/gameInfo";

import { FormInputField, FormError, handleSubmit } from "../form";

import TeamInfo from "./TeamInfo";

const TeamChooserPageCreateForm = reduxForm({
  form: "nicknameForm",
  onSubmit: handleSubmit(({ nickname }, dispatch, { form }) => {
    if (nickname) {
      dispatch(nicknameFormSubmitted(form, nickname));
    }
  }),
})(({ handleSubmit, pristine, submitting, error }) => (
  <form
    onSubmit={handleSubmit}
    autoComplete="off"
    className="team-chooser-page-create-form"
  >
    <div className="team-chooser-page-create-form__fields">
      {error && <FormError error={error} />}
      <Field
        name="nickname"
        component={FormInputField}
        type="text"
        label={<Localized id="team-chooser-start-party-enter-name" />}
      />
    </div>
    <div className="team-chooser-page-create-form__submit">
      <button type="submit" disabled={pristine || submitting}>
        <Localized id="form-create">create</Localized>
      </button>
    </div>
  </form>
));

const TeamChooserPageTeamList = () => {
  const dispatch = useDispatch();
  const players = useSelector((state) => getPlayers(state.session));
  const selectTeam = (name) => dispatch(nicknameSetRequested(name));
  return (
    <div className="team-chooser-page-team-list">
      <div className="team-chooser-page-team-list__contents">
        {!players.length && (
          <Localized id="team-chooser-join-party-no-players">
            <p>Er speelt nog niemand mee</p>
          </Localized>
        )}

        {!!players.length &&
          players.map(({ name, ids }) => (
            <div
              className="team-chooser-page-team-list__team"
              onClick={(e) => selectTeam(name)}
            >
              <span className="team-chooser-page-team-list__team-name">
                {name}
              </span>
              <span className="team-chooser-page-team-list__team-member-count">
                <TeamInfo nickname={name} label={ids.length} />
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

const TeamChooserPage = () => {
  const pin = useSelector((state) => getPin(state.session));
  const { url } = urlForPin(pin);
  return (
    <div className="team-chooser-page">
      <div className="team-chooser-page__header">
        <div className="team-chooser-page__header__url">URL: {url}</div>
        <div className="team-chooser-page__header__pin">PIN: {pin}</div>
      </div>

      <div className="team-chooser-page__contents">
        <div className="team-chooser-page__create">
          <Localized id="team-chooser-start-party-title">
            <h2>Start een party</h2>
          </Localized>
          <Localized id="team-chooser-start-party-intro">
            <p>
              Je kunt alleen in een party zitten en individueel spelen of
              anderen kunnen zich bij je team voegen.
            </p>
          </Localized>
          <TeamChooserPageCreateForm />
        </div>

        <div className="team-chooser-page__choose">
          <Localized id="team-chooser-join-party-title">
            <h2>Voeg je bij een party</h2>
          </Localized>
          <Localized id="team-chooser-join-party-intro">
            <p>
              Kies een bestaande party om samen te spelen. Als je er geen ziet
              kun je er zelf een starten.
            </p>
          </Localized>
          <TeamChooserPageTeamList />
        </div>
      </div>
    </div>
  );
};

export default TeamChooserPage;
