/**
 * Calculates the server time difference based on a (preferably not heavy)
 * client invoked interaction with the server which returns the server
 * time and information on when the interaction started and ended.
 *
 * The return value of this function is normally an integer value representing
 * the time difference between the client at hand and the server.
 *
 * @returns {number}
 */
export default (startTime, endTime, serverTime) => {
  if (!validDate(startTime) || !validDate(endTime) || !validDate(serverTime)) {
    return 0;
  }
  const roundtripTime = endTime - startTime;
  const latency = Math.round(roundtripTime / 2);
  return endTime - serverTime - latency;
};

const validDate = (date) => date && date.getTime && !isNaN(date.getTime());
