import { all, takeLeading } from "redux-saga/effects";

import * as action from "../action";
import loginFormSubmittedSaga from "./loginFormSubmittedSaga";
import restoreLoginSaga from "./restoreLoginSaga";
import {
  gameInfoWatcher,
  gameStartedWatcher,
  gameResetWatcher,
  countdownUpdatedWatcher,
} from "./gameSaga";

export default function* sessionSaga() {
  yield all([
    restoreLoginSaga(),
    takeLeading(action.loginFormSubmitted, loginFormSubmittedSaga),
    gameInfoWatcher(),
    gameStartedWatcher(),
    gameResetWatcher(),
    countdownUpdatedWatcher(),
  ]);
}
