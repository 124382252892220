import React from "react";
import useMount from "react-use/lib/useMount";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";

import * as action from "../../state/master/action";
import * as selector from "../../state/master/selector";
import * as sessionSelector from "../../state/session/selector";
import * as screenAction from "../../state/screen/action";
import * as screenSelector from "../../state/screen/selector";

const MasterContent = () => {
  const dispatch = useDispatch();
  useMount(() => dispatch(screenAction.screenMounted()));

  const isStarted = useSelector(({ master }) => selector.getIsStarted(master));
  const isStarting = useSelector(({ master }) =>
    selector.getIsStarting(master)
  );
  const isResetting = useSelector(({ master }) =>
    selector.getIsResetting(master)
  );
  const ended = useSelector(({ master }) => selector.getEnded(master));
  const nextFinishesGame = useSelector(({ master }) =>
    selector.getNextFinishesGame(master)
  );
  const displayEvent = useSelector(({ master }) =>
    selector.getDisplayEvent(master)
  );
  const madeChoices = useSelector(({ master }) =>
    selector.getMadeChoices(master)
  );
  const previousPossible = useSelector(({ master }) =>
    selector.getPreviousPossible(master)
  );
  const countdown = useSelector(({ session }) =>
    sessionSelector.getCountdown(session)
  );
  const presenceList = useSelector(({ screen }) =>
    screenSelector.getPresenceList(screen)
  );
  const readyList = useSelector(({ master }) => selector.getReadyList(master));

  const onStart = () => dispatch(action.gameStartRequested());
  const onNext = () => dispatch(action.gameNextRequested());
  const onReset = () => dispatch(action.gameResetRequested());
  const onStartCountdown = () => dispatch(action.countdownStartRequested());
  const onStopCountdown = () => dispatch(action.countdownStopRequested());
  const onPrevious = () => dispatch(action.gamePreviousRequested());

  const handleNextClick = () => {
    if (
      displayEvent.type === "question" &&
      displayEvent.payload.choices &&
      !displayEvent.payload.statistics &&
      Object.keys(madeChoices).length === 0
    ) {
      if (
        window.confirm(
          "Caution! None of players has given an answer, are you sure you want to continue to the next step?"
        )
      ) {
        onNext();
      }
      return;
    }

    onNext();
  };

  const handleResetClick = () => {
    if (window.confirm("This will restart the game, are you sure?")) {
      onReset();
    }
  };

  return (
    <div className="master-content">
      {!isStarted && (
        <button type="button" onClick={() => onStart()} disabled={isStarting}>
          {!isStarting ? "start" : "starting ..."}
        </button>
      )}
      {isStarted && (
        <React.Fragment>
          {!!displayEvent &&
            !!displayEvent.payload &&
            !!displayEvent.payload.ask_if_ready && (
              <div>
                {readyList.length} of {presenceList.length} are ready
              </div>
            )}
          <div className="master-content__next">
            {(() => {
              if (ended) {
                return (
                  <button type="button" disabled>
                    finished
                  </button>
                );
              }

              return (
                <button type="button" onClick={handleNextClick}>
                  {nextFinishesGame ? "finish" : "next"}
                </button>
              );
            })()}

            {Object.keys(madeChoices).length > 0 && (
              <p>
                # made choices:{" "}
                <strong>{Object.keys(madeChoices).length}</strong>
              </p>
            )}
          </div>
          <div className="master-content__previous">
            <button
              type="button"
              onClick={() => onPrevious()}
              disabled={!previousPossible}
            >
              previous
            </button>
          </div>
          {countdown &&
            (() => {
              const [, remaining, state] = countdown;

              return (
                <div
                  className={cx(
                    "master-content__countdown",
                    {
                      "master-content__countdown--started": state === "started",
                    },
                    {
                      "master-content__countdown--stopped": state === "stopped",
                    }
                  )}
                >
                  Countdown: {remaining}
                  &nbsp;
                  <button
                    type="button"
                    onClick={() =>
                      state === "started"
                        ? onStopCountdown()
                        : onStartCountdown()
                    }
                    disabled={remaining === 0 || ended}
                  >
                    {state === "started" && "pause"}
                    {state === "stopped" && "resume"}
                  </button>
                </div>
              );
            })()}
          <div className="master-content__reset">
            <button
              type="button"
              onClick={handleResetClick}
              disabled={isResetting}
            >
              {!isResetting ? "reset" : "resetting ..."}
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default MasterContent;
