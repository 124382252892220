import React from "react";

import { takeEvery, put, all, call, select } from "redux-saga/effects";
import get from "lodash/get";
import find from "lodash/find";

import { showToast, showRawHTMLToast } from "../../../toast";
import { Localized } from "../../../translation";
import Metric from "../../../component/Metric";
import matchChannelEvent from "../../socket/util/matchChannelEvent";
import channelPushSaga from "../../socket/util/channelPushSaga";

import * as action from "../action";
import * as selector from "../selector";
import { getScreenAsPlayer } from "../../session";

import getChannelName from "./getChannelName";

const channelEventMatch = (event) => (action) =>
  matchChannelEvent(getChannelName(), event)(action);

function* displayEventSagaWatcher() {
  yield takeEvery(channelEventMatch("display_event"), function* ({
    payload: { display_event },
  }) {
    if (display_event && display_event.type === "question") {
      const recentQuestionId = yield select(
        (state) => state.player.recentQuestionId
      );
      const questionId = display_event.payload.id;
      const questionChanged = recentQuestionId !== questionId;

      /**
       * Clear the made choice when a new question is received
       * (an event is received that has a different id than the
       * most recently known one)
       */
      if (questionChanged) {
        yield put(action.clearMadeChoice());
      }

      /**
       * Show a toast when we receive a question event with hint data.
       */
      const madeChoice = yield select((state) => state.player.madeChoice);
      const hint = get(display_event, [
        "payload",
        "choices",
        madeChoice,
        "hint",
      ]);
      const hintDuration = get(display_event, [
        "payload",
        "choices",
        madeChoice,
        "hint_duration",
      ]);
      if (hint) {
        const autoClose = (hintDuration ? hintDuration : 30) * 1000;
        yield call(showRawHTMLToast, hint, { autoClose });
      }
    }

    yield put(action.displayEventReceived(display_event));
  });
}

function* gameStartedWatcher() {
  yield takeEvery(channelEventMatch("game_started"), function* () {
    yield put(action.gameStarted());
  });
}

function* gameResetWatcher() {
  yield takeEvery(channelEventMatch("game_reset"), function* () {
    yield put(action.gameReset());
  });
}

function* messageWatcher() {
  yield takeEvery(channelEventMatch("message"), function ({
    payload: { content },
  }) {
    showToast(content);
  });
}

function* scoreUpdatedWatcher() {
  yield takeEvery(channelEventMatch("score_updated"), function* ({
    payload: { score },
  }) {
    // read metrics ... if one of them changed, toast
    const currentMetrics = yield select(({ player }) =>
      selector.getMetrics(player)
    );
    score.metrics.forEach((metric) => {
      const currentMetric = find(currentMetrics, { id: metric.id });
      if (!currentMetric) {
        return;
      }

      const currentMetricValue = currentMetric.value;
      if (metric.value === currentMetricValue) {
        return;
      }

      if (metric.hidden) {
        return;
      }

      showToast(<Metric metric={metric} previousValue={currentMetricValue} />, {
        autoClose: 3000,
      });
    });

    const currentScore = yield select(({ player }) =>
      selector.getScore(player)
    );
    if (score.points && currentScore !== score.points) {
      showToast(
        <Metric
          metric={{ id: "score", name: "Score", value: score.points }}
          previousValue={currentScore}
        />,
        { autoClose: 3000 }
      );
    }

    yield put(action.scoreUpdated(score));
  });
}

function* choiceUpdatedWatcher() {
  yield takeEvery(channelEventMatch("choice_updated"), function* ({ payload }) {
    const { choice } = payload;
    yield put(action.choiceUpdated(choice));
  });
}

function* teamInfoWatcher() {
  yield takeEvery(channelEventMatch("team_info"), function* ({ payload }) {
    const newIsTeamLeader = payload.is_team_leader;
    const screenAsPlayer = yield select((state) =>
      getScreenAsPlayer(state.session)
    );
    const currentIsTeamLeader = yield select((state) =>
      selector.getIsTeamLeader(state.player)
    );
    if (
      screenAsPlayer &&
      newIsTeamLeader &&
      newIsTeamLeader !== currentIsTeamLeader
    ) {
      showToast(<Localized id="became-team-leader-message" />);
    }
    yield put(action.isTeamLeaderUpdated(newIsTeamLeader));
  });
}

function* choiceWatcher() {
  yield takeEvery(action.makeChoiceRequested, function* ({ payload: index }) {
    const channel = yield call(getChannelName);
    const choiceIndexToSend = index !== undefined ? index : null;
    const { ok } = yield call(channelPushSaga, channel, "make_choice", {
      choice_index: choiceIndexToSend,
    });
    if (ok) {
      yield put(action.choiceUpdated(index));
    }
  });
}

function* isReadyChangeRequestedWatcher() {
  yield takeEvery(action.isReadyChangeRequested, function* ({
    payload: is_ready,
  }) {
    const channel = yield call(getChannelName);
    yield call(channelPushSaga, channel, "is_ready", { is_ready });
  });
}

function* isReadyUpdatedWatcher() {
  yield takeEvery(channelEventMatch("is_ready_updated"), function* ({
    payload,
  }) {
    yield put(action.isReadyUpdated(payload.choice));
  });
}

export default function* () {
  yield all([
    displayEventSagaWatcher(),
    gameStartedWatcher(),
    gameResetWatcher(),
    messageWatcher(),
    scoreUpdatedWatcher(),
    choiceWatcher(),
    choiceUpdatedWatcher(),
    isReadyChangeRequestedWatcher(),
    isReadyUpdatedWatcher(),
    teamInfoWatcher(),
  ]);
}
