import React from "react";
import moment from "moment";

import ScreenCountdown from "../screen/Countdown";

const Countdown = ({ to, className }) => (
  <ScreenCountdown to={to} when={(remaining) => remaining > 0}>
    {(remaining) => (
      <div className={className}>
        {remaining >= 0
          ? moment.utc(remaining * 1000).format("HH:mm:ss")
          : "00:00:00"}
      </div>
    )}
  </ScreenCountdown>
);

Countdown.propTypes = { ...ScreenCountdown.propTypes };

export default Countdown;
