import React from "react";
import cx from "classnames";
import HTMLContent from "../HTMLContent";
import { DefaultPlayer as Video } from "react-html5video";

const ScreenIntro = ({ displayEvent }) => {
  const { intro: content } = displayEvent.payload;
  return (
    <div
      className={cx(
        "screen-intro",
        `screen-intro--content-type--${content.type}`
      )}
    >
      <div className="screen-intro__content">
        {(() => {
          switch (content.type) {
            case "video":
              const {
                video: { type, url },
              } = content;
              return (
                <Video autoPlay>
                  <source src={url} type={type} />
                </Video>
              );
            case "image":
              return <img src={content.image.src} alt="" />;
            case "html":
              return <HTMLContent html={content.html} />;
            case "text":
              return <div>{content.text}</div>;
            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
};

export default ScreenIntro;
