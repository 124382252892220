import React from "react";
import cx from "classnames";
import sanitizeHtml from "sanitize-html";
import any from "lodash/fp/any";

const stripHtml = (string) =>
  sanitizeHtml(string, { allowedTags: [], allowedAttributes: {} });

const PlayerButtons = ({ choices, madeChoice, onChoice = (index) => {} }) => {
  const hasChoiceWithShortAnswer = any((x) => x.short_text, choices);
  return (
    <div
      className={cx(
        "player-buttons",
        `player-buttons--count--${choices.length}`,
        {
          "player-buttons--has-choice-with-short-answer": hasChoiceWithShortAnswer,
        },
        {
          "player-buttons--not-has-choice-with-short-answer": !hasChoiceWithShortAnswer,
        },
        { "player-buttons--made-choice": madeChoice !== undefined },
        { "player-buttons--not-made-choice": madeChoice === undefined }
      )}
    >
      <div className="player-buttons__content">
        {choices.map((choice, index) => {
          const chosen = madeChoice !== undefined && madeChoice === index;
          const handleClick = () =>
            onChoice(madeChoice === undefined ? index : undefined);
          return (
            <button
              className={cx(
                "player-buttons__button",
                `player-buttons__button--index--${index}`,
                { "player-buttons__button--chosen": chosen },
                { "player-buttons__button--not-chosen": !chosen },
                { [choice.class]: choice.class }
              )}
              key={index}
              type="button"
              //disabled={madeChoice !== undefined}
              onClick={handleClick}
            >
              <span>
                {stripHtml(
                  choice.short_text ? choice.short_text : choice.content
                )}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default PlayerButtons;
