import React from "react";
import { Localized } from "../../translation";

import ObjectModal from "./ObjectModal";

const RepositoryModal = (props) => (
  <ObjectModal
    {...props}
    classPrefix="repository-"
    header={<Localized id="repository">repository</Localized>}
    intro={<Localized id="repository-intro">repository-intro</Localized>}
  />
);

export default RepositoryModal;
