import React from "react";

import { Localized } from "../../translation";

import PlayerInfo from "./PlayerInfo";

const PlayerWaitingForGame = () => (
  <Localized id="player-waiting-for-game">
    <PlayerInfo>Wacht tot het spel begint ...</PlayerInfo>
  </Localized>
);

export default PlayerWaitingForGame;
