import React from "react";
import moment from "moment";
import Countdown from "./Countdown";

const ScreenCountdown = ({ to, className }) => (
  <Countdown to={to} when={(remaining) => remaining > 0}>
    {(remaining) => (
      <div className={className}>
        {remaining >= 0
          ? moment.utc(remaining * 1000).format("HH:mm:ss")
          : "00:00:00"}
      </div>
    )}
  </Countdown>
);

ScreenCountdown.propTypes = { ...Countdown.propTypes };

export default ScreenCountdown;
