import { call, put } from "redux-saga/effects";

import { setMasterPassword } from "../../authStorage";

import * as action from "../action";

export default function* () {
  yield call(setMasterPassword);
  yield put(action.masterLoggedOut());
}
