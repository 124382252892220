import React from "react";

import FormFieldWrapper from "./FormFieldWrapper";

const FormInputField = (props) => {
  const { input, type } = props;
  return (
    <FormFieldWrapper {...props}>
      {({ inputId }) => <input {...input} type={type} id={inputId} />}
    </FormFieldWrapper>
  );
};

export default FormInputField;
