import { createAction } from "redux-act";

export const loginRequested = createAction("login requested", (pin) => ({
  pin,
}));
export const loginSucceeded = createAction("login succeeded");
export const loginFailed = createAction("login failed", (error) => error);

export const loginFormSubmitted = createAction(
  "login form submitted",
  (form, pin) => ({ form, pin })
);

export const showLoggingIn = createAction("show logging in");
export const hideLoggingIn = createAction("hide logging in");

export const logoutRequested = createAction("logout requested");

export const localeSelected = createAction("locale set", (locale) => locale);
export const localeDeselected = createAction("locale deselected");

export const customCSSAdded = createAction("custom CSS added", (css) => css);
export const customCSSRemoved = createAction("custom CSS removed");

export const setScreenAsPlayer = createAction(
  "set whether to use the combined screen/player functionality",
  (value) => value
);

export const gameInfoReceived = createAction(
  "game info received",
  (info) => info
);
export const gameStarted = createAction("game started");
export const gameReset = createAction("game was reset");
export const countdownUpdated = createAction(
  "countdown updated",
  (countdown) => countdown
);

export const serverTimeDifferenceCalculated = createAction(
  "server time difference calculated"
);
