import { createReducer } from "redux-act";
import update from "immutability-helper";

import { defaultLocale } from "../../translation";

import * as action from "./action";

const initialState = {
  loggingIn: false,
  loginError: undefined,
  loggedIn: false,
  customCSS: undefined,
  locale: defaultLocale,
  started: false,
  serverTimeDifference: 0,
  scenarioName: undefined,
  pin: undefined,
  hintToastIds: [],
  countdown: undefined,
  screenAsPlayer: undefined,
  players: [],
};

export default createReducer(
  {
    [action.loginRequested]: (state) =>
      update(state, {
        loggingIn: { $set: true },
        loginError: { $set: undefined },
      }),
    [action.loginSucceeded]: (state) =>
      update(state, {
        loggingIn: { $set: false },
        loggedIn: { $set: true },
      }),
    [action.loginFailed]: (state, payload) =>
      update(state, {
        loggingIn: { $set: false },
        loginError: { $set: payload },
      }),

    [action.showLoggingIn]: (state) =>
      update(state, {
        loggingIn: { $set: true },
      }),
    [action.hideLoggingIn]: (state) =>
      update(state, {
        loggingIn: { $set: false },
      }),

    [action.logoutRequested]: (state) =>
      update(state, {
        loggedIn: { $set: false },
      }),

    [action.localeSelected]: (state, payload) =>
      update(state, { locale: { $set: payload } }),
    [action.localeDeselected]: (state) =>
      update(state, { locale: { $set: defaultLocale } }),

    [action.customCSSAdded]: (state, payload) =>
      update(state, {
        customCSS: { $set: payload },
      }),
    [action.customCSSRemoved]: (state) =>
      update(state, {
        customCSS: { $set: undefined },
      }),

    [action.setScreenAsPlayer]: (state, payload) =>
      update(state, { screenAsPlayer: { $set: !!payload } }),

    [action.gameInfoReceived]: (
      state,
      { started, scenario_name, pin, countdown, players }
    ) =>
      update(state, {
        started: { $set: started },
        scenarioName: { $set: scenario_name },
        pin: { $set: pin },
        countdown: { $set: countdown },
        players: { $set: players },
      }),
    [action.gameStarted]: (state) => update(state, { started: { $set: true } }),
    [action.gameReset]: (state) => update(state, { started: { $set: false } }),
    [action.countdownUpdated]: (state, countdown) =>
      update(state, { countdown: { $set: countdown } }),

    [action.serverTimeDifferenceCalculated]: (state, payload) =>
      update(state, { serverTimeDifference: { $set: payload } }),
  },
  initialState
);
