import React from "react";

import { Localized } from "../../translation";

import PlayerMeta from "./PlayerMeta";
import PlayerInfo from "./PlayerInfo";

const PlayerIntro = () => (
  <>
    <PlayerMeta />
    <Localized id="player-intro">
      <PlayerInfo>player-intro</PlayerInfo>
    </Localized>
  </>
);

export default PlayerIntro;
