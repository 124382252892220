import { applyMiddleware } from "redux";

import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";

import { createSocketMiddleware } from "./socket/middleware";
import { SOCKET_URL } from "../settings";

const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger({ collapsed: true });
const socketMiddleware = createSocketMiddleware(SOCKET_URL);

const middlewares = [socketMiddleware, sagaMiddleware, loggerMiddleware];

export default applyMiddleware(...middlewares);
export { sagaMiddleware };
