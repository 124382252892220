import { take, call, put } from "redux-saga/effects";

import * as playerAction from "../../player";
import * as masterAction from "../../master";
import * as socketAction from "../../socket";
import { setAuth } from "../../authStorage";

import * as action from "../action";

function* logoutSaga() {
  yield take(action.logoutRequested);
  yield call(logout);
}

function* logout() {
  yield call(setAuth, undefined);
  yield put(action.customCSSRemoved());
  yield put(playerAction.nicknameUnsetRequested());
  yield put(playerAction.playernameUnsetRequested());
  yield put(masterAction.masterLogoutRequested());
  yield put(socketAction.disconnectRequested());
}

export { logoutSaga, logout };
