import React from "react";
import { FluentBundle } from "fluent";
import { LocalizationProvider } from "fluent-react";
import { negotiateLanguages } from "fluent-langneg";
import axios from "axios";

import en from "./translation/en.ftl";
import nl from "./translation/nl.ftl";

const supportedLocalesFiles = { en, nl };
const supportedLocales = Object.keys(supportedLocalesFiles);
const defaultLocale = supportedLocales[0];

async function fetchMessages(locale) {
  const response = await axios.get(supportedLocalesFiles[locale]);
  const messages = response.data;
  return { [locale]: messages };
}

async function createBundlesGenerator(currentLocales) {
  const fetched = await Promise.all(currentLocales.map(fetchMessages));
  const messages = fetched.reduce((acc, messages) => ({ ...acc, ...messages }));
  return function* generateBundles() {
    for (const locale of currentLocales) {
      const bundle = new FluentBundle(locale);
      bundle.addMessages(messages[locale]);
      yield bundle;
    }
  };
}

class AppLocalizationProvider extends React.Component {
  constructor(props) {
    super(props);

    const { selectedLocale } = props;
    const currentLocales = negotiateLanguages(
      [selectedLocale],
      supportedLocales,
      { defaultLocale }
    );

    this.state = {
      currentLocales,
      bundles: undefined,
    };
  }

  async componentDidMount() {
    const { currentLocales } = this.state;
    const generateBundles = await createBundlesGenerator(currentLocales);
    const bundles = generateBundles();
    this.setState({ bundles });
  }

  render() {
    const { children } = this.props;
    const { bundles } = this.state;

    if (!bundles) {
      return null;
    }

    return (
      <LocalizationProvider bundles={bundles}>{children}</LocalizationProvider>
    );
  }
}

export { Localized } from "fluent-react";
export { AppLocalizationProvider, defaultLocale };
