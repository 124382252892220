import { createAction } from "redux-act";

export const masterLoginFormSubmitted = createAction(
  "master login form submitted",
  (form, password) => ({ form, password })
);

export const masterLogoutRequested = createAction("master logout requested");

export const masterLoggedIn = createAction("master logged in");
export const masterLoggedOut = createAction("master logged out");

export const gameInfoReceived = createAction(
  "game info received",
  (info) => info
);
export const displayEventReceived = createAction(
  "display event received",
  (displayEvent) => displayEvent
);
export const madeChoicesReceived = createAction(
  "made choices received",
  (choices) => choices
);
export const readyListReceived = createAction(
  "ready list received",
  (readyList) => readyList
);

export const gameStartRequested = createAction("game start requested");
export const gameStartStarted = createAction("game start started");
export const gameStartSucceeded = createAction("game start succeeded");
export const gameStartFailed = createAction("game start failed");

export const gameNextRequested = createAction("game next requested");
export const gameNextStarted = createAction("game next started");
export const gameNextSucceeded = createAction("game next succeeded");
export const gameNextFailed = createAction("game next failed");

export const gamePreviousRequested = createAction("game previous requested");
export const previousPossibleReceived = createAction(
  "game previous possible received"
);
export const nextFinishesGameReceived = createAction(
  "next finishes game received"
);

export const gameResetRequested = createAction("game reset requested");
export const gameResetStarted = createAction("game reset started");
export const gameResetSucceeded = createAction("game reset succeeded");
export const gameResetFailed = createAction("game reset failed");

export const livestreamCreateRequested = createAction(
  "livestream create requested"
);
export const livestreamStartRequested = createAction(
  "livestream start requested"
);
export const livestreamStopRequested = createAction(
  "livestream stop requested"
);
export const livestreamDeleteRequested = createAction(
  "livestream delete requested"
);

export const countdownStartRequested = createAction(
  "countdown start requested"
);
export const countdownStopRequested = createAction("countdown stop requested");
