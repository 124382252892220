export const getNickname = ({ nickname }) => nickname;
export const getPlayername = ({ playername }) => playername;
export const getIsTeamLeader = ({ isTeamLeader }) => isTeamLeader;
export const getScore = ({ score }) => score;
export const getRank = ({ rank }) => rank;
export const getDisplayEvent = ({ displayEvent }) => displayEvent;
export const getMadeChoice = ({ madeChoice }) => madeChoice;
export const getMetrics = ({ metrics }) => metrics;
export const getOpenRepository = ({ openRepository }) => openRepository;
export const getOpenInventory = ({ openInventory }) => openInventory;
export const getIsReady = ({ isReady }) => isReady;
