import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";

import PlayerPage from "./player/PlayerPage";
import ScreenPage from "./screen/ScreenPage";
import ScreenAsPlayerPage from "./screenAsPlayer/ScreenAsPlayerPage";
import MasterRoute from "./master/MasterRoute";
import MasterPage from "./master/MasterPage";
import StreamPage from "./master/StreamPage";

const Router = ({ children }) => <HashRouter>{children}</HashRouter>;

const Routes = () => (
  <Switch>
    <Route exact path="/" component={PlayerPage} />
    <Route exact path="/screen" component={ScreenPage} />
    <MasterRoute exact path="/master" component={MasterPage} />
    <MasterRoute exact path="/stream" component={StreamPage} />
  </Switch>
);

const RoutesForScreenAsPlayer = () => (
  <Switch>
    <Route exact path="/" component={ScreenAsPlayerPage} />
    <MasterRoute exact path="/master" component={MasterPage} />
    <MasterRoute exact path="/stream" component={StreamPage} />
  </Switch>
);

export { Router, Routes, RoutesForScreenAsPlayer };
export { Link, NavLink, withRouter } from "react-router-dom";
