import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import session from "./session";
import screen from "./screen";
import player from "./player";
import master from "./master";
import socket from "./socket";

const rootReducer = combineReducers({
  session,
  screen,
  player,
  master,

  socket,
  form,
});

export default rootReducer;
