import React from "react";
import cx from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Metric = ({ metric, previousValue }) => {
  const difference =
    previousValue !== undefined ? metric.value - previousValue : undefined;
  return (
    <OverlayTrigger
      key={metric.id}
      placement="bottom"
      overlay={(props) => (
        <Tooltip id={`metric-tooltip-${metric.id}`} {...props}>
          {metric.name}
        </Tooltip>
      )}
    >
      <div
        className={cx(
          "metric",
          `metric--id--${metric.id}`,
          { "metric--value--zero": !metric.value },
          { "metric--value--not-zero": !!metric.value }
        )}
      >
        <div className="metric__name">{metric.name}</div>
        {!!difference ? (
          <div className="metric__difference">
            {difference > 0 && "+"}
            {difference < 0 && "-"} {Math.abs(difference)}
          </div>
        ) : (
          <div className="metric__value">{metric.value}</div>
        )}
      </div>
    </OverlayTrigger>
  );
};

export default Metric;
