export const getLoggedIn = ({ loggedIn }) => loggedIn;
export const getIsStarted = ({ isStarted }) => isStarted;
export const getIsStarting = ({ isStarting }) => isStarting;
export const getIsResetting = ({ isResetting }) => isResetting;
export const getEnded = ({ ended }) => ended;
export const getMadeChoices = ({ madeChoices }) => madeChoices;
export const getPreviousPossible = ({ previousPossible }) => previousPossible;
export const getNextFinishesGame = ({ nextFinishesGame }) => nextFinishesGame;
export const getDisplayEvent = ({ displayEvent }) => displayEvent;
export const getReadyList = ({ readyList }) => readyList;
