import { call } from "redux-saga/effects";

import { masterChannelPushSaga as pushSaga } from "./masterChannelPushSaga";

export function* livestreamCreateRequestedSaga() {
  yield call(pushSaga, "create_livestream");
}

export function* livestreamStartRequestedSaga() {
  yield call(pushSaga, "start_livestream");
}

export function* livestreamStopRequestedSaga() {
  yield call(pushSaga, "stop_livestream");
}

export function* livestreamDeleteRequestedSaga() {
  yield call(pushSaga, "delete_livestream");
}
