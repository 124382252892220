import React, { useRef } from "react";
import { useOverflow as cannedUseOverflow } from "use-overflow";
import useMount from "react-use/lib/useMount";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import cx from "classnames";
import moment from "moment";

import { Form } from "react-bootstrap";

import {
  screenMounted,
  getDisplayEvent,
  getPresenceList,
} from "../../state/screen";
import {
  getServerTimeDifference,
  getScenarioName,
  getPin,
  getCountdown,
} from "../../state/session";
import {
  getNickname,
  getPlayername,
  getIsTeamLeader,
  getMadeChoice,
  getMetrics,
  getScore,
  getOpenRepository,
  getIsReady,
  closeRepositoryRequested,
  getOpenInventory,
  closeInventoryRequested,
  makeChoiceRequested,
  isReadyChangeRequested,
} from "../../state/player";
import { Localized } from "../../translation";

import LivestreamPlayer from "../screen/LivestreamPlayer";

import Leaderboard from "../Leaderboard";
import Metric from "../Metric";

import PlayernameChooserPage from "./PlayernameChooserPage";
import TeamChooserPage from "./TeamChooserPage";
import WaitingForGame from "./WaitingForGame";
import Intro from "./Intro";
import Question from "./Question";
import HeaderMenu from "./HeaderMenu";
import TeamInfo from "./TeamInfo";
import RepositoryModal from "./RepositoryModal";
import InventoryModal from "./InventoryModal";

const useScreen = () => {
  const dispatch = useDispatch();
  useMount(() => dispatch(screenMounted()));

  return useSelector(({ session, screen, player }) => ({
    serverTimeDifference: getServerTimeDifference(session),
    scenarioName: getScenarioName(session),
    pin: getPin(session),
    countdown: getCountdown(session),
    displayEvent: getDisplayEvent(screen),
    presenceList: getPresenceList(screen),
    nickname: getNickname(player),
    isTeamLeader: getIsTeamLeader(player),
    madeChoice: getMadeChoice(player),
  }));
};

const useOverflow = (givenOverflowRef) => {
  const hookOverflowRef = useRef(null);
  const overflowRef = givenOverflowRef || hookOverflowRef;
  const overflow = cannedUseOverflow(overflowRef);

  const overflowClasses = (prefix) =>
    cx(
      { [`${prefix}overflow-y-active`]: overflow.refYOverflowing },
      { [`${prefix}not-overflow-y-active`]: !overflow.refYOverflowing },
      { [`${prefix}overflow-y-scroll-begin`]: overflow.refYScrollBegin },
      { [`${prefix}not-overflow-y-scroll-begin`]: !overflow.refYScrollBegin },
      { [`${prefix}overflow-y-scroll-end`]: overflow.refYScrollEnd },
      { [`${prefix}not-overflow-y-scroll-end`]: !overflow.refYScrollEnd }
    );

  return {
    ...overflow,
    overflowRef,
    overflowClasses,
  };
};

const ScreenAsPlayerPage = () => {
  const dispatch = useDispatch();
  const {
    serverTimeDifference,
    scenarioName,
    pin,
    countdown,
    displayEvent,
    presenceList,
    nickname,
    isTeamLeader,
    madeChoice,
  } = useScreen();

  const { overflowRef, overflowClasses } = useOverflow();

  const metrics = useSelector(({ player }) => getMetrics(player));
  const score = useSelector(({ player }) => getScore(player));
  const isReady = useSelector(({ player }) => getIsReady(player));
  const playername = useSelector(({ player }) => getPlayername(player));

  const repository = useSelector(({ player }) => player.repository);
  const openRepository = useSelector(({ player }) => getOpenRepository(player));
  const handleRepositoryClose = () => dispatch(closeRepositoryRequested());

  const inventory = useSelector(({ player }) => player.inventory);
  const openInventory = useSelector(({ player }) => getOpenInventory(player));
  const handleInventoryClose = () => dispatch(closeInventoryRequested());

  const handleChoice = (index) => {
    if (isTeamLeader) {
      dispatch(makeChoiceRequested(index));
    }
  };
  const handleIsReadyChange = () => {
    dispatch(isReadyChangeRequested(!isReady));
  };

  if (!playername) {
    return <PlayernameChooserPage />;
  }

  if (!nickname) {
    return <TeamChooserPage />;
  }

  const displayEventType = displayEvent && displayEvent.type;

  const visibleMetrics = metrics ? metrics.filter((x) => !x.hidden) : [];

  return (
    <div
      ref={overflowRef}
      className={cx(
        "screen-as-player-page",
        {
          [`screen-as-player-page--display-event-type--${displayEventType}`]: !!displayEventType,
        },
        { "screen-as-player-page--display-event": !!displayEvent },
        { "screen-as-player-page--not-display-event": !displayEvent },
        { "screen-as-player-page--is-team-leader": !!isTeamLeader },
        { "screen-as-player-page--not-is-team-leader": !isTeamLeader },

        overflowClasses("screen-as-player-page--")
      )}
    >
      <LivestreamPlayer />
      {repository.length > 0 && (
        <RepositoryModal
          objects={repository}
          show={openRepository}
          handleClose={handleRepositoryClose}
        />
      )}
      {inventory.length > 0 && (
        <InventoryModal
          objects={inventory}
          show={openInventory}
          handleClose={handleInventoryClose}
        />
      )}
      <div className="screen-as-player-page__header">
        <div className="screen-as-player-page__header__menu">
          <HeaderMenu />
        </div>
        <div className="screen-as-player-page__header__team">
          <TeamInfo nickname={nickname} />
        </div>
        <div className="screen-as-player-page__header__score">
          {score !== undefined && (
            <Metric metric={{ id: "score", name: "Score", value: score }} />
          )}
        </div>
        <div className="screen-as-player-page__header__metrics">
          {visibleMetrics.map((metric, index) => (
            <Metric key={index} metric={metric} />
          ))}
        </div>

        {countdown &&
          (() => {
            const [, remaining] = countdown;
            return (
              <div className="screen-as-player-page__header__countdown">
                {remaining > 0 ? (
                  moment.utc(remaining * 1000).format("HH:mm:ss")
                ) : (
                  <Localized id="screen-as-player-page-header-countdown">
                    Time is up!
                  </Localized>
                )}
              </div>
            );
          })()}

        {get(displayEvent, "payload.ask_if_ready") && (
          <div className="screen-as-player-page__header__is-ready">
            <Form.Switch
              label="I am ready"
              id="isReady"
              name="isReady"
              disabled={!isTeamLeader}
              onChange={handleIsReadyChange}
              value={!!isReady}
              checked={!!isReady}
            />
          </div>
        )}

        <div className="screen-as-player-page__header__logo"></div>
      </div>

      <div className="screen-as-player-page__content">
        {(() => {
          if (!displayEvent) {
            return (
              <WaitingForGame
                scenarioName={scenarioName}
                pin={pin}
                presenceList={presenceList}
              />
            );
          }

          let key = get(displayEvent, "payload.id");
          if (!key) {
            key = JSON.stringify(displayEvent);
          }

          switch (displayEvent.type) {
            case "intro":
              if (!displayEvent.payload) {
                return null;
              }
              return <Intro key={key} displayEvent={displayEvent} />;
            case "question":
              return (
                <Question
                  key={key}
                  nickname={nickname}
                  displayEvent={displayEvent}
                  madeChoice={madeChoice}
                  serverTimeDifference={serverTimeDifference}
                  onChoice={handleChoice}
                />
              );
            case "leaderboard":
              return (
                <Leaderboard
                  key={key}
                  displayEvent={displayEvent}
                  renderNickname={(nickname) => (
                    <TeamInfo nickname={nickname} />
                  )}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
      <div className="screen-as-player-page__overflow-indicator"></div>
    </div>
  );
};

export default ScreenAsPlayerPage;
