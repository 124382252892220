import React from "react";
import cx from "classnames";

import HTMLContent from "../HTMLContent";

import Countdown from "./Countdown";

const QuestionTextAndChoices = ({
  text,
  choices,
  madeChoice,
  screenText,
  onChoice,
  choicesEndTime,
  serverTimeDifference,
}) => (
  <div
    className={cx(
      "screen-as-player-question",
      "screen-as-player-question--display--text-and-choices",
      `screen-as-player-question--choices-count--${choices.length}`,
      {
        "screen-as-player-question--made-choice": madeChoice !== undefined,
      },
      {
        "screen-as-player-question--not-made-choice": madeChoice === undefined,
      }
    )}
  >
    <div className="screen-as-player-question__content">
      <HTMLContent html={text} />
    </div>
    {screenText ? (
      <div className="screen-as-player-question__content">
        <HTMLContent html={screenText} />
      </div>
    ) : (
      <div className="screen-as-player-question__choices">
        {choices.map((choice, index) => {
          const chosen = madeChoice !== undefined && madeChoice === index;
          const handleClick = () =>
            onChoice(madeChoice === undefined ? index : undefined);
          return (
            <div
              className={cx(
                "screen-as-player-question__choice",
                `screen-as-player-question__choice--index--${index}`,
                { "screen-as-player-question__choice--chosen": chosen },
                {
                  "screen-as-player-question__choice--not-chosen": !chosen,
                },
                { [choice.class]: choice.class }
              )}
              key={index}
              onClick={handleClick}
            >
              <div className="screen-as-player-question__choice__content">
                <HTMLContent html={choice.content} />
              </div>
            </div>
          );
        })}
      </div>
    )}
    {choicesEndTime &&
      (() => {
        const correctedTime =
          new Date(choicesEndTime).getTime() + serverTimeDifference;
        const correctedTimeObj = new Date();
        correctedTimeObj.setTime(correctedTime);
        return (
          <Countdown
            to={correctedTimeObj}
            className="screen-as-player-question__countdown"
          />
        );
      })()}
  </div>
);

export default QuestionTextAndChoices;
