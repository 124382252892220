import React from "react";

import { Localized } from "../translation";

const AppReconnecting = () => (
  <Localized id="app-reconnecting">
    <div className="app-reconnecting">Reconnecting ...</div>
  </Localized>
);

export default AppReconnecting;
