import React from "react";
import { Localized } from "../../translation";

import ObjectModal from "./ObjectModal";

const InventoryModal = (props) => (
  <ObjectModal
    {...props}
    classPrefix="inventory-"
    header={<Localized id="inventory">inventory</Localized>}
    intro={<Localized id="inventory-intro">inventory-intro</Localized>}
  />
);

export default InventoryModal;
