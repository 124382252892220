import { createReducer } from "redux-act";
import update from "immutability-helper";

import * as action from "./action";

const initialState = {
  displayEvent: undefined,
  presenceList: [],
  teamPresence: {},
  livestream: undefined,
};

export default createReducer(
  {
    [action.displayEventReceived]: (state, payload) =>
      update(state, { displayEvent: { $set: payload } }),
    [action.gameStarted]: (state) =>
      update(state, { displayEvent: { $set: undefined } }),
    [action.gameReset]: (state) =>
      update(state, { displayEvent: { $set: undefined } }),

    [action.gamePresenceReceived]: (state, presenceList) =>
      update(state, { presenceList: { $set: presenceList } }),

    [action.gameTeamPresenceReceived]: (state, presence) =>
      update(state, { teamPresence: { $set: presence } }),

    [action.livestreamUpdated]: (state, livestream) =>
      update(state, { livestream: { $set: livestream } }),
  },
  initialState
);
