import { takeEvery, put } from "redux-saga/effects";

import matchChannelEvent from "../../socket/util/matchChannelEvent";
import * as action from "../action";
import getLobbyChannelName from "./getLobbyChannelName";

const lobbyChannelEventMatch = (event) => (action) =>
  matchChannelEvent(getLobbyChannelName(), event)(action);

export function* gameInfoWatcher() {
  yield takeEvery(lobbyChannelEventMatch("game_info"), function* ({ payload }) {
    const { game_info } = payload;
    yield put(action.gameInfoReceived(game_info));
  });
}

export function* gameStartedWatcher() {
  yield takeEvery(lobbyChannelEventMatch("game_started"), function* () {
    yield put(action.gameStarted());
  });
}

export function* gameResetWatcher() {
  yield takeEvery(lobbyChannelEventMatch("game_reset"), function* () {
    yield put(action.gameReset());
  });
}

export function* countdownUpdatedWatcher() {
  yield takeEvery(lobbyChannelEventMatch("countdown_updated"), function* ({
    payload: { countdown },
  }) {
    yield put(action.countdownUpdated(countdown));
  });
}
