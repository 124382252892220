const get = (key) => JSON.parse(sessionStorage.getItem(key));
const set = (key, value) => {
  if (value !== undefined) {
    sessionStorage.setItem(key, JSON.stringify(value));
  } else {
    sessionStorage.removeItem(key);
  }
};

const AUTH_STORAGE = "auth/auth";
export const getAuth = () => get(AUTH_STORAGE);
export const setAuth = (auth) => set(AUTH_STORAGE, auth);
export const getSessionId = () => {
  const auth = getAuth();
  return auth && auth.session_id;
};

const NICKNAME_STORAGE = "auth/nickname";
export const getNickname = () => get(NICKNAME_STORAGE);
export const setNickname = (nickname) => set(NICKNAME_STORAGE, nickname);

const PLAYERNAME_STORAGE = "auth/playername";
export const getPlayername = () => get(PLAYERNAME_STORAGE);
export const setPlayername = (playername) =>
  set(PLAYERNAME_STORAGE, playername);

const MASTER_STORAGE = "auth/master";
export const getMasterPassword = () => get(MASTER_STORAGE);
export const setMasterPassword = (password) => set(MASTER_STORAGE, password);
