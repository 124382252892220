import uniq from "lodash/fp/uniq";

export const getLoggingIn = (state) => state.loggingIn;
export const getLoginError = (state) => state.loginError;

export const getLoggedIn = (state) => state.loggedIn;
export const getLoggedInAsMaster = (state) => state.loggedInAsMaster;

export const getCustomCSS = (state) => state.customCSS;

export const getScreenAsPlayer = (state) => state.screenAsPlayer;

export const getServerTimeDifference = (state) => state.serverTimeDifference;

export const getScenarioName = (state) => state.scenarioName;
export const getPin = (state) => state.pin;

export const getCountdown = (state) => state.countdown;

export const getPlayers = ({ players }) => {
  const orderedNames = uniq(players.map(([name]) => name));
  const idsByName = players.reduce(
    (acc, [name, id]) => ({ ...acc, [name]: [...(acc[name] || []), id] }),
    {}
  );
  return orderedNames.map((name) => ({ name, ids: idsByName[name] }));
};
