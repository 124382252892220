import React from "react";
import { Player, BigPlayButton, ControlBar } from "video-react";
import HLSSource from "../HLSSource";

import { useSelector } from "react-redux";
import { getLivestream } from "../../state/screen/selector";

const LivestreamPlayer = () => {
  const livestream = useSelector(({ screen }) => getLivestream(screen));

  if (!livestream || livestream.state !== "started") {
    return null;
  }

  return (
    <div className="livestream-player">
      <Player autoPlay>
        <BigPlayButton position="center" />
        <ControlBar disableCompletely={true} />
        <HLSSource isVideoChild src={livestream.output_hls} />
      </Player>
    </div>
  );
};

export default LivestreamPlayer;
